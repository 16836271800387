import { useMutation } from 'react-query'
import { getAxios, Util } from '../../common'
import { ServerError } from '../../types/Common'
import { AxiosError } from 'axios'

const api = getAxios(false)
const apiAuth = getAxios(true)

/*====================Validate Password =====================*/
type validatePasswordParam = {
  phoneNumber: string
  password: string
}
const validatePassword = async (params: validatePasswordParam) => {
  const url = `/signup/validatepassword`
  const body = {
    'phone-number': params.phoneNumber,
    password: params.password,
  }
  return await api.post(url, body)
}

export const useValidatePassword = (
  onSuccessCallback: (x: string) => void,
  onErrorCallback: (x: ServerError) => void
) => {
  return useMutation(validatePassword, {
    onSuccess: (data) => {
      const password = data?.data?.password || ''
      onSuccessCallback(password)
    },
    onError: (error: AxiosError) => {
      onErrorCallback(error.response?.data as ServerError)
    },
  })
}

/*==================== Generate Password ====================*/
export type generatePasswordParam = {
  phoneNumber: string
}
const generatePassword = async (params: generatePasswordParam) => {
  const url = `/signup/generatepassword`
  const body = {
    'phone-number': params.phoneNumber,
  }
  return await api.post(url, body)
}
export const useGeneratePassword = (
  onSuccessCallback: (
    password: string,
    variables: generatePasswordParam
  ) => void,
  onErrorCallback: (x: ServerError) => void
) => {
  return useMutation(generatePassword, {
    onSuccess: (data, variables) => {
      const password = data?.data?.password || ''
      onSuccessCallback(password, variables)
    },
    onError: (error: AxiosError) => {
      onErrorCallback(error.response?.data as ServerError)
    },
  })
}

/*==================== Link Password ====================*/
type linkPasswordParam = {
  phoneNumber: string
  password: string
  invitationId?: string
}
const linkPassword = async (params: linkPasswordParam) => {
  const url = `/signup/linkpassword`

  const body = {
    'phone-number': params.phoneNumber,
    password: params.password,
    'invitation-id': params.invitationId || undefined,
  }

  return await apiAuth.post(url, Util.removeUndefinedProps(body))
}

export const useLinkPassword = (
  onSuccessCallback?: (data: any, variables: any) => void,
  onErrorCallback?: (x: ServerError) => void
) => {
  return useMutation(linkPassword, {
    onSuccess: onSuccessCallback,
    onError: (error: AxiosError) => {
      onErrorCallback?.(error.response?.data as ServerError)
    },
  })
}

/*==================== Regenerate Password ====================*/
type regeneratePasswordParam = {
  phoneNumber: string
}
const regeneratePassword = async (params: regeneratePasswordParam) => {
  const url = `/signup/regeneratepassword`
  const body = {
    'phone-number': params.phoneNumber,
  }
  return await api.post(url, body)
}
export const useRegeneratePassword = (
  onSuccessCallback: (password: string) => void,
  onErrorCallback: (error: ServerError) => void
) => {
  return useMutation(regeneratePassword, {
    onSuccess: (data) => {
      const password = data?.data?.password || ''
      onSuccessCallback(password)
    },
    onError: (error: AxiosError) => {
      onErrorCallback(error.response?.data as ServerError)
    },
  })
}
