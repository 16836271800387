export default {
  HOME: '/',
  SETTINGS: '/settings',
  LOGIN: '/login',
  ROLE: '/role',
  CHAT: '/chats',
  BUSINESS_PROFILE: '/business-profile',
  PROFILE: '/profile',
  NOTIFICATIONS: '/notifications',
  FIND: '/find',
  SIGNUP: '/signup',
  ADD_NEW_ACCOUNT: '/add-new-account',
  CLAIM_BUSINESS: '/claim',
  RESET_PASSWORD: '/reset-password',
  CLIPS_CENTRAL: '/clips-central',
  CLIP: '/clip',
  CONTACTS: '/contacts',
  SUCCESSWAIT: '/success',
  CLAIMSUCCESSWAIT: '/claim-success',
  MAGIC_LINK: '/magic-link',
  MAGIC_LINK_ACTIVE: '/magic-link-active',
  MAGIC_LINK_PENDING_WORKER: '/magic-link-pending',
  DEFAULT_REDIRECT: '/redirect',

  //use this for page layout testing
  TEST_PAGE: '/test',
}
