import { Clip, SlideType } from '../types/Files'
import { ClipsSlideShow } from '@gotradie/gt-components'
import VideoPlayer from './media/VideoPlayer'
import Image from './media/Image'
import { Constants } from '../common'
import Avatar from './Avatar'
import React from 'react'
import { StyleSheet, View } from 'react-native'

const styles = StyleSheet.create({
  imageWrapper: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

type ClipPreviewProps = {
  clipObj: Clip
  height: string | number
  width: string | number
}

const ClipPreview = (props: ClipPreviewProps) => {
  const { clipObj, height, width } = props

  const clipFullObj: Clip = clipObj as unknown as Clip

  const slides: any[] = clipFullObj.slides.map((value) => ({
    'slide-type': value.slideType,
    url:
      value.slideType === SlideType.photo || value.slideType === SlideType.empty
        ? value.slideKey
        : value.url,
    'slide-key': value.slideKey,
    'thumbnail-key': value?.thumbnailKey,
    'business-name': value?.businessName,
    'business-logo': value?.businessLogo,
  }))

  return (
    <ClipsSlideShow
      VideoComponent={(props: any) => {
        return (
          <VideoPlayer
            source={props?.videoSignedurl}
            s3Key={props?.videoKey}
            videoProps={{
              ...props,
              resizeMode: 'contain',
              width: width,
              height: width,
            }}
            hideControllers={true}
            onEnd={() => props.onEnd()}
            isPlaying={true}
          />
        )
      }}
      ImageComponent={(props: any) => {
        return (
          <View style={styles.imageWrapper}>
            <Image
              size={Constants.IMAGE_SIZES.STORY}
              s3Key={props.imageKey}
              imageProps={{ ...props, resizeMode: 'contain' }}
              imageFetchType={Constants.IMAGE_FETCH_TYPE.PHOTO}
              mediaNotAvailablePlaceholderUri={require('../images/not-available-clip.png')}
              mediaNotSupportedPlaceholderUri={require('../images/not-supported-clip.png')}
            />
          </View>
        )
      }}
      AvatarComponent={(props: any) => {
        if (props.imageKey) {
          return (
            <Avatar
              avatarProps={props}
              s3Key={props.imageKey}
              imageFetchType={Constants.IMAGE_FETCH_TYPE.PROFILE}
            />
          )
        }
        return null
      }}
      description={clipObj.clipDescription}
      height={height}
      width={width}
      location={clipObj?.location?.address || ''}
      slideArray={slides}
      title={clipObj.clipTitle}
    />
  )
}

export default React.memo(ClipPreview)
