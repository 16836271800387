import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query'
import {
  Constants,
  QueryKeys,
  getAxios,
  Util,
  MessageUtils,
} from '../../common'
import { useActiveOrgId } from '../../context/UserContext'
import {
  Thread,
  ParticipantAffiliations,
  Invitees,
  UnreadThreadCounts,
  ThreadSearchResult,
  MentionAffiliation,
  MentionMetadata,
} from '../../types/Thread'
import {
  Media,
  Document,
  Clip,
  Slide,
  GalleyType,
  GalleryItem,
  GalleryTab,
} from '../../types/Files'

const api = getAxios(true)

//--------------------Get threads by filter----------------------------
const fetchThreads = async (threadFilter: string, pageParam = '') => {
  const pageSize = Constants.PAGE_SIZES.THREAD_FETCH_BATCH_SIZE
  return await api.get(
    `/threads?thread-filter=${threadFilter}&page-size=${pageSize}&start-key=${pageParam}`
  )
}

export const useFetchThreads = (threadFilter: string, enabled = true) => {
  return useInfiniteQuery(
    [QueryKeys.THREADS, threadFilter, useActiveOrgId()],
    ({ pageParam = null }) => fetchThreads(threadFilter, pageParam),
    {
      enabled,
      select: (data) => ({
        ...data,
        pages: (data?.pages || []).flatMap((x) => {
          return x.data.threads.map((thread: any) => dtoToThread(thread))
        }),
      }),
      getNextPageParam: (response: any) =>
        response?.data?.metadata.start_key || false,
      cacheTime: Constants.DURATION.DAY,
      staleTime: Constants.DURATION.SECOND,
    }
  )
}

export function dtoToThread(response: any) {
  const thread = {} as Thread
  thread.admins = response.admins
  thread.groupName = response['group-name']
  thread.threadId = response['thread-id']
  thread.creatorOrgId = response['creator-org-id']
  thread.createdBy = response['created-by']
  thread.creatorRole = response['creator-role']
  thread.participants = response['participants']
  thread.groupIcon = response['group-chat-icon']
  thread.groupPrivacy = response['group-privacy']
  thread.overrideGroupName = response['override-group-name'] || false

  thread.groupThreadMetaData = {
    groupVisibility: Util.replaceUndefinedWithDefault(
      response['group-visibility'],
      ''
    ),
    groupPrivacy: Util.replaceUndefinedWithDefault(
      response['group-privacy'],
      'private'
    ),
  }

  thread.gtCreatedTime = response['gt-created-time']
  thread.gtUpdatedTime = response['gt-updated-time']

  const invitees: Invitees = {}
  const inviteesAttr = response['invitees'] || {}
  for (const key in inviteesAttr) {
    if (inviteesAttr.hasOwnProperty(key)) {
      const inviteeObj = inviteesAttr[key]
      invitees[key] = {
        threadType: inviteeObj['thread-type'],
        status: inviteeObj['status'],
      }
    }
  }
  thread.invitees = invitees

  const affiliations: ParticipantAffiliations = {}
  const affiliationAttr = response['participant-affiliations'] || {}
  for (const key in affiliationAttr) {
    const participant = affiliationAttr[key]
    affiliations[key] = {
      userId: participant['user-id'],
      affiliationType: participant['affiliation-type'],
      businessId: participant['business-id'],
      businessLogo: participant['business-logo'],
      businessName: participant['business-name'],
      effectiveUserId: key,
      firstName: participant['first-name'],
      lastName: participant['last-name'],
      profilePic: participant['profile-pic'],
      roleType: participant['role-type'],
      roleTemplateDisplayName: participant['role-template-display-name'],
      participantStatus: participant['participant-status'],
      email: participant['email'],
      threadFilters: participant['thread-filters'],
    }
  }

  thread.participantAffiliations = affiliations
  thread.participants = response['participants']
  thread.pendingInvitees = response['pending-invitees'] || []
  thread.postedBy = response['posted-by']

  const summary = Util.replaceUndefinedWithDefault(response['summary'], {})
  if (Object.keys(summary).length > 0) {
    thread.summary = {
      message: summary['message'],
      lastPostedBy: summary['last-posted-by'],
      messageId: summary['message-id'],
      threadType: summary['thread-type'],
      messageSentTime: summary['message-sent-time'],
      documentsCount: summary['documents-count'],
      imagesCount: summary['images-count'],
      videosCount: summary['videos-count'],
      audiosCount: summary['audios-count'],
      clipsCount: summary['clips-count'],
    }
  }

  thread.threadType = response['thread-type']
  thread.readInfo = response['read-info']
  thread.muteInfo = response['mute-info']

  return thread
}

//------------------Get thread by id------------------------------
const fetchThread = async (threadId: string) => {
  return await api.get(`/threads/${threadId}`)
}

export const useFetchThreadById = (threadId: string, enabled = true) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useQuery(
    [QueryKeys.THREAD, threadId, activeOrgId],
    () => fetchThread(threadId),
    {
      enabled,
      select: (data) => {
        let output: Thread | undefined
        if (!!data?.data?.threads) {
          //fetched data
          output = dtoToThread(data?.data?.threads)
        } else if (!!data?.data.threadId) {
          //primed data
          output = data.data
        }
        return output
      },
      cacheTime: Constants.DURATION.DAY,
      staleTime: Constants.DURATION.SECOND,
      onSettled() {
        MessageUtils.invalidateNotificationIndicatorQueries(
          queryClient,
          activeOrgId
        )
      },
    }
  )
}

//------------------Get Thread Media------------------------------
const fetchThreadMedia = async (
  threadId: string,
  source: string,
  pageParam: string
) => {
  const pageSize = Constants.PAGE_SIZES.MESSAGE_GALLERY_MEDIA_BATCH_SIZE
  return await api.get(
    `/threads/${threadId}/media?source=${source}&page-size=${pageSize}&start-key=${pageParam}`
  )
}

export const useFetchThreadMedia = (
  threadId: string,
  source: string,
  enabled = true,
  groupItems = true,
  externalData: GalleryItem[] = []
) => {
  return useInfiniteQuery(
    [QueryKeys.THREAD_MEDIA, threadId, source, useActiveOrgId()],
    ({ pageParam }) => fetchThreadMedia(threadId, source, pageParam),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        const startKey = lastPage?.data['start-key']
        if (startKey) {
          return startKey
        } else {
          return undefined
        }
      },
      cacheTime: Constants.DURATION.HOUR,
      staleTime: Constants.DURATION.SECOND,
      select: (data) => ({
        ...data,
        pages: groupItems
          ? Util.filterMediaByDate([
              ...externalData,
              ...getGalleryItems(data, source),
            ])
          : getGalleryItems(data, source),
      }),
    }
  )
}

function getGalleryItems(data: any, source: string) {
  return (data?.pages || []).flatMap((x: any, indexPage: number) => {
    return (x.data[source] || []).map((item: any, index: number) => {
      item['index'] =
        indexPage * Constants.PAGE_SIZES.MESSAGE_GALLERY_MEDIA_BATCH_SIZE +
        index
      if (source === GalleryTab.media) {
        return dtoToMediaObj(item)
      } else if (source === GalleryTab.docs) {
        return dtoToDocObj(item)
      } else if (source === GalleryTab.clips) {
        return dtoToClipsObj(item)
      }
      return null
    })
  })
}

function dtoToMediaObj(response: any) {
  const media = {} as Media

  media.mediaType = Util.replaceUndefinedWithDefault(
    response['media-type'],
    GalleyType.photo
  )

  media.index = response['index']
  media.galleryType = Util.getGalleyTypeOfMedia(media.mediaType)
  media.tab = GalleryTab.media
  media.recordKey = `${media.mediaType}_${response['source-id']}_${response['object-id']}`
  media.sourceId = response['source-id']
  media.userId = response['user-id']
  media.uploadedByUserId = media.userId
  media.uploadStatus = response['upload-status']
  media.imageType = Util.replaceUndefinedWithDefault(response['image-type'], '')
  media.source = response['source']
  media.sourceStatus = response['source-status']
  media.gtUpdatedTime = response['gt-updated-time']
  media.effectiveUserId = response['effective-user-id']
  media.threadId = response['thread-id']
  media.objectId = response['object-id']
  media.gtCreatedTime = response['gt-created-time']
  media.businessId = response['business-id']
  media.messageId = response['message-id']
  media.url = response['url']

  const metaData = response['metadata']
  if (metaData) {
    media.metaData = {
      gtUpdatedTime: metaData['gt-updated-date'],
      extension: metaData['extension'],
      contentType: metaData['ContentType'],
      gtCreatedTime: metaData['gt-created-time'],
      origin: metaData['origin'],
      businessId: metaData['business-id'],
      mediaCategory: metaData['media-category'],
      uploadedByUserId: metaData['uploaded-by-user-id'],
      contentLength: metaData['ContentLength'],
      fileName: metaData['file-name'],
      mediaSource: Util.replaceUndefinedWithDefault(
        metaData['media-source'],
        ''
      ),
      uploadedByEffectiveUserId: Util.replaceUndefinedWithDefault(
        metaData['uploaded-by-effective-user-id'],
        ''
      ),
      threadId: Util.replaceUndefinedWithDefault(metaData['thread-id'], ''),
      originId: Util.replaceUndefinedWithDefault(metaData['origin-id'], ''),
    }
    media.mimeType = metaData['ContentType']
    media.extension = metaData['extension']
  }

  if (media.mimeType === undefined) {
    if (media.mediaType === GalleyType.photo) {
      media.mimeType = 'image/jpeg'
    } else if (media.mediaType === GalleyType.video) {
      media.mimeType = 'video/mp4'
    } else if (media.mediaType === GalleyType.audio) {
      media.mimeType = 'audio/mpeg'
    }
  }

  return media
}

function dtoToDocObj(response: any) {
  const doc = {} as Document
  doc.galleryType = GalleyType.docs
  doc.tab = GalleryTab.docs
  doc.index = response['index']
  doc.recordKey = `doc_${response['source-id']}_${response['object-id']}`
  doc.effectiveUserId = response['effective-user-id']
  doc.fileName = response['file-name']
  doc.gtCreatedTime = response['gt-created-time']
  doc.gtUpdatedTime = response['gt-updated-time']
  doc.imageType = response['image-type']
  doc.mediaType = response['media-type']
  doc.messageId = response['message-id']
  doc.objectId = response['object-id']
  doc.origin = response['origin']
  doc.source = response['source']
  doc.sourceId = response['source-id']
  doc.sourceStatus = response['source-status']
  doc.threadId = response['thread-id']
  doc.uploadStatus = response['uploaded-status']
  return doc
}

function dtoToClipsObj(response: any) {
  const clip = {} as Clip
  clip.index = response['index']
  clip.galleryType = GalleyType.clips
  clip.tab = GalleryTab.clips
  clip.recordKey = `clip_${response['clip-id']}`
  clip.origin = response['origin']
  clip.clipTitle = Util.replaceUndefinedWithDefault(response['clip-title'], '')
  const servicesAttr: string[] = Util.replaceUndefinedWithDefault(
    response['tagged-services'],
    []
  )
  if (servicesAttr.length > 0) {
    const services: string[] = []
    for (const service of servicesAttr) {
      services.push(service)
    }
    clip.taggedServices = services
  }

  const location = Util.replaceUndefinedWithDefault(response['location'], null)
  if (location && location.coordinates) {
    clip.location = {
      streetAddress: Util.replaceUndefinedWithDefault(
        location['street_address'],
        ''
      ),
      address: Util.replaceUndefinedWithDefault(location['address'], ''),
      route: Util.replaceUndefinedWithDefault(location['route'], ''),
      streetNumber: Util.replaceUndefinedWithDefault(
        location['street_number'],
        ''
      ),
      coordinates: {
        latitude: location.coordinates['latitude'],
        longitude: location.coordinates['longitude'],
      },
      name: Util.replaceUndefinedWithDefault(location['name'], ''),
      suburb: Util.replaceUndefinedWithDefault(location['suburb'], ''),
      state: Util.replaceUndefinedWithDefault(location['state'], ''),
      postalCode: Util.replaceUndefinedWithDefault(location['postal_code'], ''),
      subPremise: Util.replaceUndefinedWithDefault(location['subpremise'], ''),
    }
  }
  clip.coverImage = response['cover-image']
  clip.addToHighlights = response['add-to-highlights']

  const slides = Util.replaceUndefinedWithDefault(response['slides'], [])
  if (slides) {
    const arSlides: Slide[] = []
    for (const slide of slides) {
      arSlides.push({
        slideSequence: slide['slide-sequence'],
        slideId: slide['slide-id'],
        thumbnailKey: slide['thumbnail-key'],
        slideKey: slide['slide-key'],
        slideType: slide['slide-type'],
        clipId: slide['clip-id'],
        gtCreatedTime: slide?.['gt-created-time'] || '',
      })
    }
    clip.slides = arSlides
  }

  clip.admins = response['admins']
  clip.clipId = response['clip-id']
  clip.gtUpdatedTime = response['gt-updated-time']

  if (response['highlighted-entities']) {
    clip.highlightedEntities = {}
    const highlightedEntities = response['highlighted-entities']
    for (const key in highlightedEntities) {
      if (highlightedEntities.hasOwnProperty(key)) {
        const entity = highlightedEntities[key]
        clip.highlightedEntities[key] = {
          entityType: entity['entity-type'],
        }
      }
    }
  }
  clip.businessHandle = response['business-handle']
  clip.gtCreatedTime = response['gt-created-time']
  clip.threadId = response['thread-id']
  clip.postedBy = response['posted-by']
  clip.uploadedByUserId = clip.postedBy
  clip.clipDescription = Util.replaceUndefinedWithDefault(
    response['clip-description'],
    ''
  )
  clip.businessId = response['business-id']
  const taggedEntities = Util.replaceUndefinedWithDefault(
    response['tagged-entities'],
    null
  )
  if (taggedEntities) {
    clip.taggedEntities = {}
    for (const key in taggedEntities) {
      if (taggedEntities.hasOwnProperty(key)) {
        const entity = taggedEntities[key]
        clip.taggedEntities[key] = {
          taggedStatus: entity['tagged-status'],
          tagEntityType: entity['tag-entity-type'],
          businessName: entity['business-name'],
          handle: entity['handle'],
          businessId: entity['business-id'],
          businessLogo: entity['business-logo'],
          phoneNumber: entity['phone-number'],
          profilePic: entity['profile-pic'],
          userId: entity['user-id'],
          effectiveUserId: entity['effective-user-id'],
          firstName: entity['first-name'],
          email: entity['email'],
          lastName: entity['last-name'],
        }
      }
    }
  }
  clip.clipUrl = `${process.env.BUSINESS_PROFILE_URL}/${clip.businessHandle}/clips/${clip.clipId}`
  return clip
}

//------------------Fetch All Threads-----------------------------

const fetchAllThreads = async (pageParam: string) => {
  const pageSize = Constants.PAGE_SIZES.THREAD_FETCH_BATCH_SIZE
  return await api.get(`/threads?page-size=${pageSize}&start-key=${pageParam}`)
}

export const useFetchAllThreads = (enabled = true) => {
  return useInfiniteQuery(
    [QueryKeys.THREADS_ALL, useActiveOrgId()],
    ({ pageParam }) => fetchAllThreads(pageParam),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        const startKey = lastPage?.data['start-key']
        if (startKey) {
          return startKey
        } else {
          return undefined
        }
      },
      select: (data) => ({
        ...data,
        pages: (data?.pages || []).flatMap((x) => {
          return x.data.threads.map((thread: any) => dtoToThread(thread))
        }),
      }),
    }
  )
}

//-------------------Get Thread Unread Count-------------------

const fetchUnreadThreadsCount = async (
  threadFilter: string,
  options: string
) => {
  return await api.get(
    `/threads/unreadcount?thread-filter=${threadFilter}&options=${options}`
  )
}

export const useFetchUnreadThreadsCount = (
  enabled = true,
  threadFilter = '',
  options = 'full-unread-count'
) => {
  return useQuery(
    [QueryKeys.UNREAD_THREADS_COUNT, threadFilter, useActiveOrgId()],
    () => fetchUnreadThreadsCount(threadFilter, options),
    {
      enabled,
      select: (resp) => {
        return dtoToUnreadThreadCount(resp?.data || {})
      },
      cacheTime: Constants.DURATION.MINUTE * 2,
      staleTime: Constants.DURATION.SECOND,
    }
  )
}

const dtoToUnreadThreadCount = (response: any) => {
  if (response['full-unread-breakdown']) {
    const unreadResponse = response['full-unread-breakdown']
    return {
      clients: unreadResponse['clients'] ? unreadResponse['clients'] : 0,
      teams: unreadResponse['teams'] ? unreadResponse['teams'] : 0,
      worksites: unreadResponse['worksites'] ? unreadResponse['worksites'] : 0,
    } as UnreadThreadCounts
  } else {
    return {
      clients: 0,
      teams: 0,
      worksites: 0,
    } as UnreadThreadCounts
  }
}

//------------------Get Worksite magic link------------------------------
const generateMagicLink = async (threadId: string, includeHistory: boolean) => {
  return await api.post(`/threads/${threadId}/magiclink`, {
    'show-history': `${includeHistory}`,
  })
}

export const useFetchMagicLink = (
  threadId: string,
  includeHistory: boolean,
  enabled = true
) => {
  return useQuery(
    [QueryKeys.MAGIC_LINK, threadId, includeHistory, useActiveOrgId()],
    () => generateMagicLink(threadId, includeHistory),
    {
      enabled,
      select: (resp) => {
        const magicLink = resp.data['magic-link']['magic-link']
        return magicLink
      },
      cacheTime: Constants.DURATION.MINUTE * 2,
    }
  )
}

//---------------Search a thread--------------------------

const fetchThreadSearchData = async (
  threadId: string,
  query: string,
  materialType: string,
  pageParam = 0
) => {
  const pageSize = Constants.PAGE_SIZES.THREAD_SEARCH_BATCH_SIZE
  return await api.get(
    `/threads/${threadId}/search?query=${query}&material-type=${materialType}&page-size=${pageSize}&page-number=${pageParam}`
  )
}

export const useFetchThreadSearchData = (
  threadId: string,
  query = '',
  materialType = '',
  enabled = false
) => {
  return useInfiniteQuery(
    [QueryKeys.THREAD_SEARCH, threadId, useActiveOrgId()],
    ({ pageParam }) =>
      fetchThreadSearchData(threadId, query, materialType, pageParam),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        const currentPage = lastPage?.data['results-metadata']?.['current-page']
        const totalPages = lastPage?.data['results-metadata']?.['total-pages']
        if (currentPage < totalPages) {
          return currentPage + 1
        } else {
          return undefined
        }
      },
      cacheTime: Constants.DURATION.MINUTE,
    }
  )
}

export function dtoToThreadSearchResult(response: any) {
  const result = {
    searchId: Util.generateUUID(),
    groupChatIcon: response['group-chat-icon'],
    effectiveParticipants: response['effective-participants'],
    groupName: response['group-name'],
    highlights: response.highlights,
    messageId: response['message-id'],
    uploadedByUserId: response['postedby-user-id'],
    createdTime: response['message-sent-time'],
    objectType: response['object-type'],
    objectID: response.objectID,
    participantBusinesses: response['participant-businesses'],
    participantNames: response['participant-names'],
    participantFirstnames: response['participant-firstnames'],
    postedbyFirstname: response['postedby-firstname'],
    postedbyLastname: response['postedby-lastname'],
    searchObjectLevel: response['search-object-level'],
    searchText: response['search-text'],
    searchSubText: response['search-sub-text'],
    threadId: response['thread-id'],
    threadType: response['thread-type'],
    fileName: response['file-name'],
    type: response['object-type'], // Keys to display videos images in gallery view
    key: response['object-key'],
    url: response.url,
    mentionsMetadata: {} as MentionMetadata,
  } as ThreadSearchResult

  if (!!response['mentions-metadata']) {
    const mentionMeta = response['mentions-metadata']
    Object.keys(mentionMeta).map((key) => {
      const obj = mentionMeta[key]
      const newObj = {
        userId: obj['user-id'],
        affiliationType: obj['affiliation-type'],
        businessId: obj['business-id'],
        businessLogo: obj['business-logo'],
        businessName: obj['business-name'],
        firstName: obj['first-name'],
        lastName: obj['last-name'],
        participantStatus: obj['participant-status'],
        profilePic: obj['profile-pic'],
        roleType: obj['role-type'],
        effectiveUserId: obj['effective-user-id'],
        email: obj['email'],
        phoneNumber: obj['phone-number'],
        name: obj['name'],
        roleTemplateDisplayName: obj['role-template-display-name'],
        roleTemplateId: obj['role-template-id'],
        showHistory: obj['show-history'],
        threadFilters: obj['thread-filters'],
      } as MentionAffiliation
      result.mentionsMetadata[key] = newObj
    })
  }

  return result
}

//---------------Get threads by given option--------------------------

type Keys = keyof typeof Constants.THREAD_FILTER_OPTIONS
type ThreadFilterOptions = typeof Constants.THREAD_FILTER_OPTIONS[Keys]
const fetchThreadsByOption = async (option: ThreadFilterOptions) => {
  return await api.get(`/threads?options=${option}`)
}

export const useFetchThreadsByOption = (
  option = Constants.THREAD_FILTER_OPTIONS.UNREAD_ONLY
) => {
  return useQuery(
    [QueryKeys.THREADS, option, useActiveOrgId()],
    () => fetchThreadsByOption(option),
    {
      select: (data) => {
        return data?.data?.threads.map((thread: any) => dtoToThread(thread))
      },
      cacheTime: Constants.DURATION.DAY,
    }
  )
}
