import { useState } from 'react'
import { View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import TabSwitcher from '../../components/tabView'
import YourClips from './tabs/YourClips'
import { Clip } from '../../types/Files'

const { styles } = StyleSheet.create({
  containerStyle: {
    flex: 1,
  },
})

type ClipsCentralSideBarProps = {
  onPressClip: (clip: Clip) => void
}

const ClipsCentralSideBar = (props: ClipsCentralSideBarProps) => {
  const { onPressClip } = props
  const tabs = [
    {
      tabKey: '1',
      title: 'Your clips',
      icon: { name: 'Clip' },
      indicator: false,
    },
  ]
  const [activeTab, setActiveTab] = useState(tabs[0].tabKey)

  return (
    <View style={styles.containerStyle}>
      <TabSwitcher
        activeTab={activeTab}
        onChangeActiveTab={(key: string) => {
          setActiveTab(key)
        }}
      >
        {/*TODO enable this later*/}
        {/* <TabHeader>
          {tabs.map((tab) => {
            return (
              <Tab
                key={tab.tabKey}
                {...tab}
                titleProps={{ body_strong: true }}
              />
            )
          })}
        </TabHeader> */}

        {tabs.map((tab, index) => (
          <YourClips key={`tab_${index}`} tab={tab} onPressClip={onPressClip} />
        ))}
      </TabSwitcher>
    </View>
  )
}

export default ClipsCentralSideBar
