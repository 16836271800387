import moment from 'moment'
import Constants from './Constants'
import Util from './Util'

class ClientWebSocket {
  private HEARBEAT_INTERVAL = 30000
  private MESSAGE_TIMEOUT_CHECKER_INTERVAL = 10000
  private MESSAGE_TIMEOUT = 30000
  private MAX_RETRY_COUNT = 3

  private static instance: ClientWebSocket | null = null
  private ws: WebSocket
  private url: string
  private activeOrgId: string
  private subscribers: ((data: any) => void)[] = []
  private keepALiveTimer: any
  private heartbeat = false
  private deviceId: string
  private wsMessageQueue: Map<string, any>
  private wsQueueTimer: any

  private constructor(currentUserId: string, activeOrgId: string) {
    this.activeOrgId = activeOrgId
    this.deviceId = Util.generateUUID()
    this.url = `${process.env.WEB_SOCKET_URL}?user-id=${currentUserId}&org-id=${activeOrgId}&device-id=${this.deviceId}`

    this.setupMessageQueue()
    this.createWebSocket()
  }

  public static getWebSocket(currentUserId: string, activeOrgId: string) {
    if (this.instance === null) {
      this.instance = new ClientWebSocket(currentUserId, activeOrgId)
    }
    return this.instance
  }

  public closeWebSocket() {
    if (this.isWebSocketOpen()) {
      this.ws.close()
    }
  }

  public isWebSocketOpen() {
    return this.ws.readyState === this.ws.OPEN
  }

  public sendMessageViaWebSocket(jsonPayload: any) {
    if (this.isWebSocketOpen()) {
      this.ws.send(this.formatWsMessage(jsonPayload))
      return true
    } else {
      return false
    }
  }

  public subscribe(subscriber: (data: any) => void) {
    this.subscribers.push(subscriber)
  }

  public unsubscribe(subscriber: (data: any) => void) {
    this.subscribers = this.subscribers.filter((fn) => fn !== subscriber)
  }

  public reconnectIfDisconnected() {
    if (!!this.ws) {
      if (this.isWebSocketOpen()) {
        this.createNewWebSocket()
      }
    }
  }

  private setupMessageQueue() {
    this.wsMessageQueue = new Map<string, any>()
    this.wsQueueTimer = setInterval(
      (self: ClientWebSocket) => {
        this.checkWsMessageQueueForUndeliveredMessages(self)
      },
      this.MESSAGE_TIMEOUT_CHECKER_INTERVAL,
      this
    )
  }

  private checkWsMessageQueueForUndeliveredMessages(self: ClientWebSocket) {
    Object.keys(self.wsMessageQueue).map((key) => {
      const queuedObj = self.wsMessageQueue.get(key)
      const timeElapsed = moment.now() - queuedObj._sent_time
      if (timeElapsed > self.MESSAGE_TIMEOUT) {
        if (queuedObj._retry_attempts < self.MAX_RETRY_COUNT) {
          //TODO: resend the message
          self.sendMessageViaWebSocket(this.formatWsMessage(queuedObj))
        } else {
          console.log(
            'unable to deliver the following message to the backend through the web socket',
            queuedObj
          )
          self.wsMessageQueue.delete(queuedObj._id)
        }
      }
    })
  }

  private createWebSocket() {
    this.ws = new WebSocket(this.url)
    this.ws.onopen = () => {
      if (this.heartbeat) {
        this.initiateHeartBeat()
      }
    }
    this.ws.onerror = (e: Event) => {
      console.log('error web socket', e)
      this.ws.close()
      this.createNewWebSocket()
    }
    this.ws.onclose = () => {}

    this.ws.onmessage = (e) => {
      try {
        const received = JSON.parse(e.data)
        if (this.isAcknowledgement(received)) {
          return
        }
        this.subscribers.forEach((callback: (data: any) => void) => {
          callback(received)
        })
      } catch (error) {
        console.log('Error received from the web socket', error)
      }
    }
  }

  private formatWsMessage(data: any) {
    const output = data
    if (!output._id) {
      output._id = Util.generateUUID()
    }

    //adding the message to a queue
    const queueObj = output
    queueObj._sent_time = moment.now()
    if (!output._retry_attempts) {
      queueObj._retry_attempts = 0
    } else {
      queueObj._retry_attempts = output._retry_attempts + 1
    }
    this.wsMessageQueue.set(queueObj._id, queueObj)

    return JSON.stringify(output)
  }

  private isAcknowledgement(data: any) {
    const isAck =
      !!data &&
      Object.keys(data).length == 2 &&
      'status' in data &&
      data['status'] == 'success'

    if (isAck) {
      this.wsMessageQueue.delete(data._id)
    }

    return isAck
  }

  private createNewWebSocket() {
    if (!!this.keepALiveTimer) {
      clearInterval(this.keepALiveTimer)
    }
    if (!!this.wsQueueTimer) {
      clearInterval(this.wsQueueTimer)
    }
    this.createWebSocket()
  }

  public initiateHeartBeat() {
    this.heartbeat = true
    if (this.isWebSocketOpen()) {
      this.keepALiveTimer = setInterval(
        (self: ClientWebSocket) => {
          sendKeepAliveMessage(self, self.activeOrgId, self.deviceId)
        },
        this.HEARBEAT_INTERVAL,
        this
      )
    }
  }
}

function sendKeepAliveMessage(
  self: ClientWebSocket,
  activeOrgId: string,
  deviceId: string
) {
  if (self.isWebSocketOpen()) {
    self.sendMessageViaWebSocket({
      action: Constants.WEB_SOCKET_MESSAGE_ACTIONS.KEEP_ALIVE,
      'org-id': activeOrgId,
      'device-id': deviceId,
    })
  }
}

export default ClientWebSocket
