import { View, TouchableOpacity } from 'react-native'
import {
  Spacer,
  ComponentOptions,
  Icon,
  AppColors,
  Text,
} from '@gotradie/gt-components'
import { Clip } from '../../types/Files'
import { Constants, Util } from '../../common'
import ClipPreview from '../ClipPreview'
import {
  ClipCentralButtonActionTypes,
  ClipLayout,
} from '../../types/ClipCentral'
import StyleSheet from 'react-native-media-query'
import useScreenType from '../../common/DimensionsUtil'
import { ScreenType } from '../../types/Common'
import ClipPreviewHighlightView from './ClipPreviewHighlightView'
import { useState } from 'react'

const { styles } = StyleSheet.create({
  wrapperStyle: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  footerContainerStyle: {
    flexDirection: 'row',
    paddingVertical: ComponentOptions.SPACES.LARGE,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  clipPreviewButtonsContainerStyle: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: ComponentOptions.SPACES.LARGE,
  },
  clipPreviewContainerStyle: {
    alignItems: 'center',
    flex: 1,
  },
  headerContainerStyle: {
    flexDirection: 'row',
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    justifyContent: 'flex-end',
    width: '100%',
  },
  headerContainerSlideModeStyle: {
    flexDirection: 'row',
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    justifyContent: 'space-between',
    width: '100%',
  },
  clipPreviewContainerSlidesStyle: {
    alignItems: 'flex-start',
    flex: 1,
    paddingLeft: ComponentOptions.SPACES.MEDIUM,
  },
  clipHeaderTopMenuRightStyle: { flexDirection: 'row' },
  clipHeaderTopMenuLeftStyle: {
    flexDirection: 'column',
    maxWidth: '70%',
  },
  clipHeaderTopMenuLeftMobileStyle: {
    flexDirection: 'column',
    maxWidth: '70%',
    overflow: 'hidden',
  },
})

type ClipPreviewButtonProps = {
  onPress: () => void
  iconName: string
  title: string
}

const ClipPreviewButton = (props: ClipPreviewButtonProps) => {
  const { onPress, iconName, title } = props
  return (
    <TouchableOpacity style={styles.clipPreviewButtonsContainerStyle}>
      <Icon
        name={iconName}
        size={22}
        fill={AppColors.common.white}
        stroke={1}
        onPress={onPress}
      />
      <Spacer size={ComponentOptions.SPACES.SMALL} />
      <Text body_small color={AppColors.common.white}>
        {title}
      </Text>
    </TouchableOpacity>
  )
}

type ClipPreviewFooterProps = {
  onPress: (action: ClipCentralButtonActionTypes) => void
}

const ClipPreviewFooter = (props: ClipPreviewFooterProps) => {
  const { onPress } = props
  return (
    <View style={styles.footerContainerStyle}>
      <ClipPreviewButton
        title={`Add more`}
        iconName={'AddImage'}
        onPress={() => onPress(ClipCentralButtonActionTypes.AddMore)}
      />
      <ClipPreviewButton
        title={`Edit clip`}
        iconName={'EditClip'}
        onPress={() => onPress(ClipCentralButtonActionTypes.EditClip)}
      />

      <ClipPreviewButton
        title={`Link`}
        iconName={'Copy'}
        onPress={() => onPress(ClipCentralButtonActionTypes.CopyLink)}
      />

      <ClipPreviewButton
        title={`Send`}
        iconName={'Send'}
        onPress={() => onPress(ClipCentralButtonActionTypes.Send)}
      />
    </View>
  )
}

type ClipPreviewHeaderProps = {
  currentLayout: ClipLayout
  onPressClose: () => void
  onPressChangeLayout: (layout: ClipLayout) => void
  clipObj: Clip
}
const ClipPreviewHeader = (props: ClipPreviewHeaderProps) => {
  const { currentLayout, onPressClose, onPressChangeLayout, clipObj } = props

  const isDesktopView = useScreenType() === ScreenType.Desktop

  return (
    <View
      style={[
        styles.headerContainerStyle,
        currentLayout === ClipLayout.slides
          ? styles.headerContainerSlideModeStyle
          : {},
      ]}
    >
      {currentLayout === ClipLayout.slides && (
        <View
          style={
            isDesktopView
              ? styles.clipHeaderTopMenuLeftStyle
              : styles.clipHeaderTopMenuLeftMobileStyle
          }
        >
          {clipObj.location && clipObj.location.name !== '' && (
            <Text body_small color={AppColors.common.white}>
              {clipObj.location?.address}
            </Text>
          )}
          <Text body_strong color={AppColors.common.white}>
            {clipObj.clipTitle}
          </Text>
          {clipObj.clipDescription !== '' && (
            <Text body_small color={AppColors.common.white}>
              {clipObj.clipDescription}
            </Text>
          )}
        </View>
      )}

      <View style={styles.clipHeaderTopMenuRightStyle}>
        <Icon
          name={currentLayout === ClipLayout.highlight ? 'Tools' : 'Highlights'}
          size={22}
          fill={AppColors.common.white}
          stroke={1}
          onPress={() =>
            onPressChangeLayout(
              currentLayout === ClipLayout.highlight
                ? ClipLayout.slides
                : ClipLayout.highlight
            )
          }
        />
        <Spacer isVertical size={ComponentOptions.SPACES.LARGE} />
        <Icon
          name={'Close'}
          size={22}
          fill={AppColors.common.white}
          stroke={1}
          onPress={onPressClose}
        />
      </View>
    </View>
  )
}

type ClipCentralPreviewProps = {
  clipObj: Clip
  onPressClose: () => void
  onPressActionButton: (action: ClipCentralButtonActionTypes) => void
  currentLayout: ClipLayout
  setCurrentLayout: (layout: ClipLayout) => void
}

const ClipCentralPreview = (props: ClipCentralPreviewProps) => {
  const {
    clipObj,
    onPressClose,
    onPressActionButton,
    currentLayout,
    setCurrentLayout,
  } = props
  const screenType = useScreenType()

  if (clipObj) {
    const onPress = (action: ClipCentralButtonActionTypes) => {
      if (action === ClipCentralButtonActionTypes.CopyLink) {
        Util.copyToClipboard(
          clipObj.clipUrl,
          () => {
            Util.showSuccessMessage(
              'Copied link to clipboard',
              Constants.TIMEOUT.DEFAULT * 3
            )
          },
          (er) => {
            Util.showErrorMessage(er)
          }
        )
      } else {
        onPressActionButton(action)
      }
    }

    const getClipDimensions = (screenTypeVal: ScreenType) => {
      let width: any = 375
      let height: any = 675

      if (screenTypeVal === ScreenType.Desktop) {
        width = 375
        height = '90%'
      } else if (screenTypeVal === ScreenType.Mobile) {
        width = '90%'
        height = '90%'
      }

      return { width, height }
    }

    const { width, height } = getClipDimensions(screenType)
    return (
      <View style={styles.wrapperStyle}>
        <View
          style={[
            styles.clipPreviewContainerStyle,
            currentLayout === ClipLayout.slides
              ? styles.clipPreviewContainerSlidesStyle
              : {},
          ]}
        >
          <ClipPreviewHeader
            onPressChangeLayout={setCurrentLayout}
            currentLayout={currentLayout}
            onPressClose={onPressClose}
            clipObj={clipObj}
          />
          {currentLayout === ClipLayout.highlight && (
            <ClipPreview clipObj={clipObj} height={height} width={width} />
          )}
          {currentLayout === ClipLayout.slides && (
            <ClipPreviewHighlightView clipObj={clipObj} />
          )}
        </View>

        <ClipPreviewFooter onPress={onPress} />
      </View>
    )
  }
  return null
}

export default ClipCentralPreview
