/* eslint-disable @typescript-eslint/no-explicit-any */
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import { Amplify, Auth } from 'aws-amplify'
import AuthConfig from './AuthConfig'

function getCurrentUser(): Promise<CognitoUser> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.currentAuthenticatedUser()
}
function getCurrentSession(): Promise<CognitoUserSession> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.currentSession()
}

async function logoutSession() {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  await Auth.signOut()
}

function loginUser(username: string, password: string): Promise<any> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.signIn(username, password)
}

function signUpUser(
  username: string,
  password: string,
  uuid: string
): Promise<any> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.signUp({
    username,
    password,
    attributes: {
      phone_number: username,
      'custom:uuid': uuid,
    },
  })
}

function signUpUserResendVerificationCode(username: string): Promise<any> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.resendSignUp(username)
}

function signUpUserConfirm(username: string, code: string): Promise<any> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.confirmSignUp(username, code)
}

function userUpdateAttributeCognito(
  user: CognitoUser,
  obj: object
): Promise<any> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.updateUserAttributes(user, obj)
}

function forgotPassword(username: string): Promise<any> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.forgotPassword(username)
}

function forgotPasswordSubmit(
  username: string,
  password: string,
  verificationCode: string
): Promise<any> {
  Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.forgotPasswordSubmit(username, verificationCode, password)
}

export default {
  getCurrentUser,
  getCurrentSession,
  logoutSession,
  loginUser,
  signUpUser,
  signUpUserResendVerificationCode,
  signUpUserConfirm,
  userUpdateAttributeCognito,
  forgotPassword,
  forgotPasswordSubmit,
}
