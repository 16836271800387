import React from 'react'
import { StyleSheet, View } from 'react-native'
import LottieAnimationWebView from './LottieAnimationWebView/LottieAnimationWebView'

const styles = StyleSheet.create({
  innerLoadingContainerStyle: {
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

function Loader() {
  return (
    <View style={styles.innerLoadingContainerStyle}>
      <LottieAnimationWebView
        height={200}
        autoplay={true}
        loop={true}
        width={250}
        name={'Loader'}
      />
    </View>
  )
}

export default Loader
