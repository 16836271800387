import { useEffect, useState } from 'react'
import {
  Password,
  Button,
  Globals,
  Spacer,
  ComponentOptions,
  AppColors,
} from '@gotradie/gt-components'
import SignUpContainerWrapper from '../../components/signUp/signUpContainerDefaultWrapper'

import { ForgotPasswordStep3Props } from '../../types/ForgotPassword'
import { useLinkPassword } from '../../hooks/auth/useMutationPassword'
import { Util } from '../../common'
import { LoginUserParam, useLoginUser } from '../../hooks/auth/useAuth'

const ForgotPasswordStep3 = (props: ForgotPasswordStep3Props) => {
  const { onCompletionCallback, data: passedData } = props
  const [txtNewPassword, setNewPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const { mutate: onLinkPassword, isLoading: isLinkingPassword } =
    useLinkPassword(
      () => {
        Util.showSuccessMessage('All Sorted, login in to your account')
        onCompletionCallback({ ...passedData })
      },
      (er) => Util.showErrorMessage(er?.message || 'Unable to link password')
    )

  const { mutate: loginUser, isLoading: isLoginUser } = useLoginUser(
    (data: any, variables: LoginUserParam) => {
      if (variables.username && txtNewPassword !== '') {
        onLinkPassword({
          phoneNumber: variables.username,
          password: txtNewPassword,
        })
      }
    },
    (er) =>
      Util.showErrorMessage(er.message || 'Something wrong when authenticate')
  )

  useEffect(() => {
    if (errorMessage !== '') {
      setErrorMessage('')
    }
  }, [txtNewPassword])

  const checkValidated = (value = '') => {
    setNewPassword(value)
    const ValidationObj = Object.keys(Globals.passwordStrengthCheck(value))
    const isPassValid = ValidationObj.length >= 2
    if (!isPassValid) {
      setErrorMessage('Please add valid password')
    } else {
      setErrorMessage('')
    }
  }

  const checkPasswordValid = (txt: string) => {
    const ValidationObj = Object.keys(Globals.passwordStrengthCheck(txt))
    const isPassValid = ValidationObj.length >= 2
    return isPassValid
  }

  const onPressContinue = () => {
    if (
      passedData.mobileNumber &&
      passedData.generatedPassword &&
      txtNewPassword !== ''
    ) {
      loginUser({
        username: passedData.mobileNumber,
        password: passedData.generatedPassword,
      })
    }
  }

  return (
    <SignUpContainerWrapper
      title={`Create new password`}
      titleColor={AppColors.common.white}
    >
      <Password
        placeholder={'Password'}
        showPasswordStrength={true}
        value={txtNewPassword}
        onChangeText={(txt: string) => checkValidated(txt)}
      />
      <Spacer />
      <Button
        onPress={onPressContinue}
        title="Set Password"
        disabled={!checkPasswordValid(txtNewPassword)}
        loading={isLoginUser || isLinkingPassword}
      />
      <Spacer size={ComponentOptions.SPACES.LARGEST} />
    </SignUpContainerWrapper>
  )
}

export default ForgotPasswordStep3
