import { useEffect, useState } from 'react'
import { View } from 'react-native'
import {
  Button,
  Spacer,
  ComponentOptions,
  OTPInput,
  Text,
  AppColors,
  Modal,
  Globals as GTGlobals,
} from '@gotradie/gt-components'
import StyleSheet from 'react-native-media-query'
import SignUpContainerWrapper from '../../components/signUp/signUpContainerDefaultWrapper'
import { Base64, Util } from '../../common'
import { ForgotPasswordStep2Props } from '../../types/ForgotPassword'
import {
  useResetPassword,
  useVerifyResetPassword,
  verifyResetPasswordParams,
} from '../../hooks/auth/useAuth'
import { useRegeneratePassword } from '../../hooks/auth/useMutationPassword'

const { styles } = StyleSheet.create({
  textContainerStyle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerWrapperStyle: {
    alignItems: 'center',
    paddingBottom: ComponentOptions.SPACES.LARGEST,
  },
})

type MessageModalProps = {
  isVisible: boolean
  mobileNumber: string
  onShowModal: (state: boolean) => void
  onResendCode: () => void
  onPressDidntReceiveCode: () => void
}

const MessageModal = (props: MessageModalProps) => {
  const {
    isVisible,
    mobileNumber,
    onShowModal,
    onResendCode,
    onPressDidntReceiveCode,
  } = props
  return (
    <Modal
      key={'modal_mobile_no'}
      isVisible={isVisible}
      title={"Didn't receive a sms with your code?"}
      width={400}
      message={
        <Text>
          <Text body color={AppColors.common.tertiary}>
            {'Double check the following number '}
          </Text>
          <Text body_strong color={AppColors.common.primary}>
            {GTGlobals.formatMobileNumber(mobileNumber)}
          </Text>
          <Text body color={AppColors.common.tertiary}>
            {
              ' is correct? If number is incorrect go ahead and enter another mobile number'
            }
          </Text>
        </Text>
      }
      acceptButtonText={'SEND'}
      cancelButtonText={'CHANGE'}
      onAccept={() => {
        onShowModal(false)
        setTimeout(
          () => onResendCode(),
          ComponentOptions.WAITING_TIME.MODAL_CLOSE
        )
      }}
      onCancel={() => {
        onShowModal(false)
        setTimeout(
          () => onPressDidntReceiveCode(),
          ComponentOptions.WAITING_TIME.MODAL_CLOSE
        )
      }}
    />
  )
}

const ForgotPasswordStep2 = (props: ForgotPasswordStep2Props) => {
  const { onCompletionCallback, data: passedData, onBackPress } = props

  const [txtOTPCode, setTxtOTPCode] = useState('')
  const [error, setError] = useState('')
  const [isModalVisible, setModalVisible] = useState(false)

  const { mutate: onReqResendCode, isLoading: isRequestingCode } =
    useResetPassword(
      () => Util.showSuccessMessage('OTP code resend to your mobile number'),
      (er) =>
        Util.showErrorMessage(er?.message || 'Unable to resend the OTP code')
    )

  const { mutate: onRegeneratePassword, isLoading: isGeneratingPassword } =
    useRegeneratePassword(
      (newGenPassword: string) => {
        if (
          passedData.mobileNumber &&
          txtOTPCode.length == ComponentOptions.VERIFY_PIN_COUNT
        ) {
          const decodedPassword = Base64.decode(newGenPassword)
          onVerifyResetPassword({
            password: decodedPassword,
            username: passedData.mobileNumber,
            verificationCode: txtOTPCode,
          })
        }
      },
      (er) => Util.showErrorMessage(er?.message || 'Unable to map password')
    )

  const { mutate: onVerifyResetPassword, isLoading: isVerifyingPassword } =
    useVerifyResetPassword(
      (data: any, variables: verifyResetPasswordParams) => {
        onCompletionCallback({
          ...passedData,
          generatedPassword: variables.password,
        })
      },
      (er) => setError(er?.message || 'Something wrong when verify the otp')
    )

  useEffect(() => {
    if (error !== '') {
      setError('')
    }
  }, [txtOTPCode])

  const onPressContinue = () => {
    if (passedData.mobileNumber) {
      onRegeneratePassword({ phoneNumber: passedData.mobileNumber })
    }
  }

  const onResendCode = () => {
    if (passedData.mobileNumber) {
      onReqResendCode(passedData.mobileNumber)
    }
  }

  return (
    <SignUpContainerWrapper
      title={`use the sms code we sent you`}
      titleColor={AppColors.common.white}
    >
      <OTPInput
        code={txtOTPCode}
        onCodeChanged={(code: string) => {
          setTxtOTPCode(code)
        }}
        onCodeFilled={(code: string) => {
          setTxtOTPCode(code)
        }}
        pinCount={ComponentOptions.VERIFY_PIN_COUNT}
        keyboardType={'number-pad'}
        autoFocusOnLoad={true}
        error={error}
      />
      <Spacer />
      <View style={styles.textContainerStyle}>
        <Text>
          <Text
            body_small
            color={AppColors.common.white}
          >{`Please verify your mobile with the SMS we sent you. Enter the ${ComponentOptions.VERIFY_PIN_COUNT} digit code for `}</Text>
          <Text body_small color={AppColors.common.primary}>
            {passedData.mobileNumber}
          </Text>
          <Text body_small color={AppColors.common.white}>
            {' into the fields above'}
          </Text>
        </Text>
      </View>
      <Spacer />
      <Button
        onPress={onPressContinue}
        title="Continue"
        disable={txtOTPCode === '' || isRequestingCode}
        loading={isGeneratingPassword || isVerifyingPassword}
      />
      <Spacer size={ComponentOptions.SPACES.LARGEST} />
      <Button
        title={"Didn't receive a code?"}
        onPress={() => setModalVisible(true)}
        buttonColor={AppColors.common.white}
        type={ComponentOptions.BUTTON_TYPES.CLEAR}
        textProps={{
          body_strong: true,
          textTransform: ComponentOptions.TEXT_TRANSFORM_OPTIONS.NONE,
        }}
        disabled={
          isRequestingCode || isVerifyingPassword || isGeneratingPassword
        }
      />
      <Spacer size={ComponentOptions.SPACES.MEDIUM} />
      <Button
        title={'SEND SMS AGAIN'}
        onPress={onResendCode}
        buttonColor={AppColors.common.tertiary}
        rightIcon={{ name: 'Mobile' }}
        loading={isRequestingCode}
        disabled={isVerifyingPassword || isGeneratingPassword}
      />
      <Spacer size={ComponentOptions.SPACES.LARGEST} />

      <MessageModal
        isVisible={isModalVisible}
        onShowModal={setModalVisible}
        onResendCode={onResendCode}
        onPressDidntReceiveCode={onBackPress}
        mobileNumber={passedData.mobileNumber as string}
      />
    </SignUpContainerWrapper>
  )
}

export default ForgotPasswordStep2
