import {
  Clip,
  GalleryItem,
  GalleryTab,
  GalleyType,
  SlideType,
  UploadCategory,
  UploadStatus,
} from '../../types/Files'
import { AppColors, Text, ComponentOptions } from '@gotradie/gt-components'
import StyleSheet from 'react-native-media-query'
import { SectionList, View } from 'react-native'
import { SlideGalleryItem } from '../../types/ClipCentral'
import { Util } from '../../common'
import ClipGalleryMediaItem from './ClipGalleryMediaItem'
import GalleryPreview from '../galleryPreview'
import { useState } from 'react'

const { styles } = StyleSheet.create({
  wrapperStyle: {
    flex: 1,
  },
  sectionHeaderWrapperStyle: {
    padding: ComponentOptions.SPACES.MEDIUM,
  },
  clipMediaSectionStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  clipMediaItemStyle: {
    borderRadius: ComponentOptions.BORDER_RADIUS.DEFAULT,
    overflow: 'hidden',
  },
  clipMediaContainerStyle: {
    height: 234,
    width: 168,
    overflow: 'hidden',
    paddingTop: 0,
    paddingBottom: ComponentOptions.SPACES.LARGE,
  },
})

type ClipSlideMediaProps = {
  items: SlideGalleryItem[]
  onPressItem: (item: SlideGalleryItem) => void
}

const ClipSlideMedia = (props: ClipSlideMediaProps) => {
  const { items, onPressItem } = props

  return (
    <View style={styles.clipMediaSectionStyle}>
      {items.map((value, index) => (
        <ClipGalleryMediaItem
          key={`ClipGalleryMediaItem${index}${value.objectId}`}
          index={index}
          columns={1}
          item={value}
          onPressItem={() => onPressItem(value)}
          containerStyle={styles.clipMediaContainerStyle}
          innerItemStyle={styles.clipMediaItemStyle}
          padding={ComponentOptions.SPACES.MEDIUM}
        />
      ))}
    </View>
  )
}

type ClipPreviewHighlightViewProps = {
  clipObj: Clip
}

const ClipPreviewHighlightView = (props: ClipPreviewHighlightViewProps) => {
  const { clipObj } = props

  const [selectedItem, setSelectedItem] = useState<GalleryItem | null>(null)

  const slides = ((clipObj as Clip)?.slides || []).map(
    (slide) =>
      ({
        ...slide,
        uploadStatus: UploadStatus.uploaded,
        uploadingStatusValue: 0,
        tab: GalleryTab.media,
        objectId: slide.slideKey,
        galleryType:
          slide.slideType === SlideType.video
            ? GalleyType.video
            : GalleyType.photo,
        uploadCategory:
          slide.slideType === SlideType.video
            ? UploadCategory.video
            : UploadCategory.photo,
      } as SlideGalleryItem)
  )
  const groupSlides = Util.filterMediaByDate(slides)

  const onPressItem = (item: SlideGalleryItem) => {
    setSelectedItem(item)
  }

  return (
    <View style={styles.wrapperStyle}>
      <SectionList
        sections={groupSlides}
        extraData={groupSlides}
        keyExtractor={(index) => `section${index}`}
        renderItem={({ item }) => {
          return (
            <ClipSlideMedia
              items={item.data as SlideGalleryItem[]}
              onPressItem={onPressItem}
            />
          )
        }}
        renderSectionHeader={({ section: { title } }) => (
          <View style={[styles.sectionHeaderWrapperStyle]}>
            <Text body color={AppColors.common.white}>
              {Util.getSectionTitleGivenYearMonth(title)}
            </Text>
          </View>
        )}
        showsHorizontalScrollIndicator={false}
      />

      <GalleryPreview
        isVisible={selectedItem !== null}
        item={selectedItem}
        onClose={() => setSelectedItem(null)}
      />
    </View>
  )
}

export default ClipPreviewHighlightView
