import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import StyleSheet from 'react-native-media-query'
import {
  Text,
  AppColors,
  Icon,
  ComponentOptions,
} from '@gotradie/gt-components'
import { Util } from '../../../common'
import './style.scss'

const { styles } = StyleSheet.create({
  controllerContainerStyle: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    backgroundColor: AppColors.common.tertiary,
    borderRadius: ComponentOptions.BORDER_RADIUS.DEFAULT,
    alignItems: 'center',
  },
  controlButtonStyle: {
    paddingRight: ComponentOptions.SPACES.MEDIUM,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  seekRangeStyle: {
    flex: 1,
  },
  timeRangeStyle: {
    alignItems: 'flex-start',
    width: 35,
  },
})

type ControllersProps = {
  containerStyles?: StyleProp<ViewStyle>
  onPlay: () => void
  onPause: () => void
  isPlaying: boolean
  currentSeek: number
  maxSeek: number
  handleSeekChange: (value: number) => void
  isCompleted: boolean
  disabled?: boolean
}

const MediaController = (props: ControllersProps) => {
  const {
    onPlay,
    onPause,
    isPlaying,
    maxSeek,
    currentSeek,
    handleSeekChange,
    isCompleted,
    disabled,
  } = props
  return (
    <View
      style={[
        styles.controllerContainerStyle,
        props.containerStyles ? props.containerStyles : {},
      ]}
    >
      <TouchableOpacity
        style={styles.controlButtonStyle}
        disabled={disabled}
        onPress={!isPlaying || isCompleted ? () => onPlay() : () => onPause()}
      >
        {!isPlaying && !isCompleted && <Icon name={'DetailedPlay'} size={20} />}
        {isPlaying && !isCompleted && <Icon name={'DetailedPause'} size={20} />}
        {isCompleted && (
          <Icon name={'Replay'} size={20} fill={AppColors.common.white} />
        )}
      </TouchableOpacity>
      <input
        style={{
          ...styles.seekRangeStyle,
          ...{
            background: `linear-gradient(to right, ${AppColors.common.white} ${
              (currentSeek * 100) / maxSeek
            }%, ${AppColors.common.subtitleText} 0px`,
          },
        }}
        type={'range'}
        min={0}
        max={Math.round(maxSeek)}
        onInput={(e) => {
          const value = e.target.value
          handleSeekChange(value)
        }}
        value={currentSeek}
        disabled={disabled}
        className={'media-controller-seeker'}
      />
      <View style={styles.timeRangeStyle}>
        <Text color={AppColors.common.white}>{`${Util.getMediaPlayerTime(
          maxSeek,
          currentSeek
        )}`}</Text>
      </View>
    </View>
  )
}

export default MediaController
