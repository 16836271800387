import { useNavigate, useParams } from 'react-router-dom'
import { AppColors } from '@gotradie/gt-components'
import StyleSheet from 'react-native-media-query'
import ClipsCentralSideBar from '../containers/clip/ClipCentralSideBar'
import ClipContent from '../containers/clip/ClipContent'
import Layout from '../layout/Layout'
import StackSidebar from '../layout/StackSideBar'
import { DefaultContentType } from '../types/Common'
import { Clip } from '../types/Files'
import { useQueryClient } from 'react-query'
import { QueryKeys, Util } from '../common'
import { AxiosResponse } from 'axios'
import NavigationKeys from '../navigation/NavigationKeys'

const { styles } = StyleSheet.create({
  shadowStyle: {
    borderColor: AppColors.common.whiteDrop,
    borderEndWidth: '1px',
    '@media (max-width: 800px)': {
      border: 'none',
    },
  },
})

const ClipsCentralPage = () => {
  const { clipId: passedClipId } = useParams()
  const queryClient = useQueryClient()

  const navigate = useNavigate()

  const onPressClip = (clip: Clip) => {
    queryClient.setQueryData([QueryKeys.CLIP, clip.clipId], {
      data: { clip: clip },
    } as AxiosResponse)
    navigate(`${NavigationKeys.CLIPS_CENTRAL}/${clip.clipId}`)
  }

  const onPressClose = () => {
    navigate(`${NavigationKeys.CLIPS_CENTRAL}`)
  }

  return (
    <Layout
      selection={passedClipId}
      defaultContentType={DefaultContentType.clips}
      renderSidebar={
        <StackSidebar
          containerStyle={styles.shadowStyle}
          screenStack={[
            {
              key: 'clips',
              title: 'Clip central',
              screen: () => <ClipsCentralSideBar onPressClip={onPressClip} />,
              onBack: () => {
                const redirectTo = Util.getValueFromQueryParameter('redirect')
                if (redirectTo) {
                  navigate(`/${redirectTo}`)
                } else {
                  navigate(NavigationKeys.HOME)
                }
              },
            },
          ]}
        />
      }
      renderContent={
        passedClipId ? (
          <ClipContent clipId={passedClipId} onPressClose={onPressClose} />
        ) : null
      }
    />
  )
}

export default ClipsCentralPage
