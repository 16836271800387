import { View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { AppColors, Button, ComponentOptions } from '@gotradie/gt-components'

const { styles } = StyleSheet.create({
  headerContainerStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
    alignItems: 'center',
  },
  headerMidLine: {
    backgroundColor: AppColors.common.concrete,
    borderRadius: ComponentOptions.SPACES.EXTRA_SMALL,
    height: 5,
    width: 150,
  },
})

type PanelHeaderProps = {
  onCancel: () => void
  cancelText?: string
  doneText?: string
  onPressDone?: () => void
}
const PanelHeader = (props: PanelHeaderProps) => {
  const { onCancel, cancelText, doneText, onPressDone } = props
  return (
    <View style={styles.headerContainerStyle}>
      <Button
        title={cancelText || 'Cancel'}
        onPress={onCancel}
        textProps={{ body: true }}
        type={ComponentOptions.BUTTON_TYPES.CLEAR}
        buttonColor={AppColors.common.subtitleText}
        paddingHorizontal={0}
      />

      <View style={styles.headerMidLine}></View>
      {doneText && (
        <Button
          title={doneText}
          onPress={onPressDone}
          textProps={{ body: true }}
          type={ComponentOptions.BUTTON_TYPES.CLEAR}
          buttonColor={AppColors.common.primary}
          paddingHorizontal={0}
        />
      )}
      {!doneText && <View />}
    </View>
  )
}

export default PanelHeader
