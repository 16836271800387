import { View, StyleSheet, FlatList } from 'react-native'
import {
  AppColors,
  GoTradieLogo,
  AppSizes,
  ComponentOptions,
  Icon,
  Spacer,
  Text,
  Button,
  Shimmer,
} from '@gotradie/gt-components'
import { setUserProperties } from 'firebase/analytics'

import {
  useFetchMyProfile,
  useFetchUserOrgRoles,
} from '../../hooks/user/useFetchUser'
import { Constants, Util } from '../../common'
import { ActiveRole, ServerError } from '../../types/Common'
import { UserOrgRole } from '../../types/User'
import Avatar from '../../components/Avatar'
import { useUserContext } from '../../context/UserContext'
import { analytics, logEvent } from '../../analytics'

const styles = StyleSheet.create({
  loginContainer: {
    textAlign: 'center',
  },
  logo: {
    marginHorizontal: 'auto',
  },
  textWrapperStyle: {
    alignItems: 'center',
    textAlign: 'center',
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
  },
  itemContainerStyle: {
    alignItems: 'center',
    padding: ComponentOptions.SPACES.MEDIUM,
    width: (AppSizes.screen.width - ComponentOptions.SPACES.MEDIUM * 3) * 0.5,
    maxWidth: '200px',
    borderRadius: ComponentOptions.BORDER_RADIUS.DEFAULT,
    backgroundColor: AppColors.common.tertiary,
  },
  headerContainerStyle: {
    alignItems: 'center',
    paddingBottom: ComponentOptions.SPACES.MEDIUM,
  },
  buttonContainerStyle: { borderRadius: 100 },
})

type OrgRoleSelectionProps = {
  onAddNewOrgRole?: () => void
  showAddNew: boolean
  onCompletionCallback: () => void
}

function OrgRoleSelection({
  onAddNewOrgRole,
  showAddNew,
  onCompletionCallback,
}: OrgRoleSelectionProps) {
  const { data: myProfile } = useFetchMyProfile(true)
  const { updateActiveRole } = useUserContext()

  const { data } = useFetchUserOrgRoles(true, (e: ServerError) => {
    Util.showErrorMessage(
      e.message ||
        'Unable to fetch user roles. Please check your network connection.'
    )
  })

  const onUpdateOrganizationRole = async (item: UserOrgRole) => {
    const activeRole: ActiveRole = {
      'org-id': item['org-id'],
      'role-type': item['role-type'],
      'user-id': item['user-id'],
      'effective-user-id': item['effective-user-id'] || '',
    }

    const orgId = item['org-id']

    setUserProperties(analytics, {
      org_id: orgId,
    })

    logEvent('org_select', { org_id: orgId })

    updateActiveRole(activeRole)
    onCompletionCallback()
  }

  type RenderItemProps = {
    item: UserOrgRole
    index: number
  }

  const renderItem = ({ item, index }: RenderItemProps) => {
    const itemContainerStyle = {
      marginRight: index % 2 === 0 ? ComponentOptions.SPACES.MEDIUM : 0,
      marginBottom: ComponentOptions.SPACES.MEDIUM,
    }
    if (item['is-loading']) {
      return (
        <View style={[styles.itemContainerStyle, itemContainerStyle]}>
          <Shimmer
            width={ComponentOptions.AVATAR_SIZE.MEDIUM}
            height={ComponentOptions.AVATAR_SIZE.MEDIUM}
            borderRadius={ComponentOptions.AVATAR_SIZE.MEDIUM}
          />
          <Spacer />
          <Shimmer width={100} height={10} />
          <Spacer size={ComponentOptions.SPACES.LARGE} />
          <Shimmer
            width={100}
            height={30}
            borderRadius={ComponentOptions.BORDER_RADIUS.DEFAULT}
          />
        </View>
      )
    } else if (item.type === 'new' && showAddNew) {
      return (
        <View style={[styles.itemContainerStyle, itemContainerStyle]}>
          <Icon
            name={'Add'}
            fill={AppColors.common.white}
            size={ComponentOptions.AVATAR_SIZE.MEDIUM}
            stroke={0.5}
          />
          <Spacer />
          <Text body_small color={AppColors.common.white}>
            {'Add account'}
          </Text>
          <Text
            body_small
            color={AppColors.common.subtitleText}
            StyleProps={{ marginTop: ComponentOptions.SPACES.EXTRA_SMALL }}
          >
            {'Create or join'}
          </Text>
          <Spacer />
          <Button
            paddingVertical={ComponentOptions.SPACES.SMALL}
            paddingHorizontal={ComponentOptions.SPACES.MEDIUM}
            containerStyle={styles.buttonContainerStyle}
            title={'CHOOSE'}
            onPress={() => {
              if (onAddNewOrgRole) {
                onAddNewOrgRole()
              }
            }}
          />
        </View>
      )
    } else if (
      item['org-type'] === Constants.ORGANIZATION_TYPES.TRADE_ORG &&
      item.details
    ) {
      const businessName = item.details['business-name'] || ''
      return (
        <View style={[styles.itemContainerStyle, itemContainerStyle]}>
          <Avatar
            s3Key={item.details['business-logo'] || ''}
            imageFetchType={Constants.IMAGE_FETCH_TYPE.PROFILE}
            avatarProps={{
              size: ComponentOptions.AVATAR_SIZE.MEDIUM,
              altText: Util.getAcronym(businessName),
              uniqueId: item['org-id'],
            }}
          />
          <Spacer />
          <Text
            body_small
            color={AppColors.common.white}
            textProps={{
              numberOfLines: 1,
              ellipsizeMode: 'tail',
            }}
          >
            {`${Util.getTitleCase(businessName)}`}
          </Text>
          <Text
            body_small
            color={AppColors.common.subtitleText}
            StyleProps={{ marginTop: ComponentOptions.SPACES.EXTRA_SMALL }}
          >
            {`${Util.getMemberRoleForDisplay(item['role-type'] as string)}`}
          </Text>
          <Spacer />
          <Button
            paddingVertical={ComponentOptions.SPACES.SMALL}
            paddingHorizontal={ComponentOptions.SPACES.MEDIUM}
            containerStyle={styles.buttonContainerStyle}
            title={'CHOOSE'}
            onPress={() => onUpdateOrganizationRole(item)}
          />
        </View>
      )
    }
    return null
  }

  return (
    <View>
      <Spacer size={ComponentOptions.SPACES.EXTRA_LARGER} />
      <GoTradieLogo width={252} height={30} style={styles.logo} />
      <Spacer size={ComponentOptions.SPACES.LARGER} />
      <View style={styles.textWrapperStyle}>
        <Text color={AppColors.common.white} body_strong>{`Welcome back ${
          (myProfile && myProfile.firstName) || ''
        }`}</Text>
      </View>
      <Spacer size={ComponentOptions.SPACES.LARGER} />
      <FlatList
        data={data}
        extraData={data}
        renderItem={renderItem}
        keyExtractor={(item, index) => `org-item-${index}`}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        numColumns={2}
      />
      <Spacer size={ComponentOptions.SPACES.MEDIUM} />
    </View>
  )
}

export default OrgRoleSelection
