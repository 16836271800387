import ErrorCodes from './ErrorCodes'

export class MediaTypeNotSupportedError extends Error {
  constructor() {
    const name = ErrorCodes.MEDIA_ERROR_CODES.MEDIA_TYPE_NOT_SUPPORTED_ERROR
    super(name)
    this.name = name
  }
}

export class MediaNotAvailableError extends Error {
  constructor() {
    const name = ErrorCodes.MEDIA_ERROR_CODES.MEDIA_NOT_AVAILABLE_ERROR
    super(name)
    this.name = name
  }
}
