import { FlatList } from 'react-native'

import { SlideGalleryItem } from '../../../types/ClipCentral'
import ClipGalleryMediaItem from '../ClipGalleryMediaItem'

type ClipSlideMediaSectionProps = {
  items: SlideGalleryItem[]
  onRetryUpload: (item: SlideGalleryItem) => void
  onRemoveUploadingItem: (item: SlideGalleryItem) => void
  setClipSlidesCount: (itemCount: number) => void
}
const ClipSlideMediaSection = (props: ClipSlideMediaSectionProps) => {
  const { items, onRetryUpload, onRemoveUploadingItem, setClipSlidesCount } = props

  setClipSlidesCount(items.length)

  return (
    <FlatList
      data={items}
      extraData={items}
      numColumns={3}
      renderItem={({ item, index }) => (
        <ClipGalleryMediaItem
          index={index}
          columns={3}
          item={item}
          onPressItem={() => {}}
          onRetryUpload={onRetryUpload}
          onRemoveUploadingItem={onRemoveUploadingItem}
          padding={1}
        />
      )}
      showsHorizontalScrollIndicator={false}
      keyExtractor={(item) => `sectionItem${item.slideId}`}
    />
  )
}

export default ClipSlideMediaSection
