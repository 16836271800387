import React from 'react'
import LinearGradient from 'react-native-linear-gradient'
import { useTheme } from '@gotradie/gt-components'
import { ScrollView } from 'react-native'

type Props = {
  children: React.ReactNode
}

const Wrapper = (props: Props) => {
  const theme = useTheme()
  return (
    <LinearGradient colors={[theme.gradient.color1, theme.gradient.color2]}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        {props.children}
      </ScrollView>
    </LinearGradient>
  )
}

export default Wrapper
