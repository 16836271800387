import { GalleryItem, Slide } from './Files'

export enum ClipCentralRightPanelTypes {
  AddSlides,
  EditClip,
  Send,
}

export enum ClipCentralButtonActionTypes {
  AddMore,
  EditClip,
  CopyLink,
  Send,
}

export interface SlideGalleryItem extends Slide, GalleryItem {}

export interface SlideGalleryItemProps {
  RightIcon?: (item: SlideGalleryItem) => React.ReactElement | null
  onPressItem: (item: SlideGalleryItem) => void
  onRemoveUploadingItem?: (item: SlideGalleryItem) => void
  onRetryUpload?: (item: SlideGalleryItem) => void
}

export type APIClipSlide = {
  'slide-type': string
  'slide-key': string
  'thumbnail-key': string
  'slide-sequence': number
  'slide-id': string
  'clip-id': string
  'uploaded-by-user-business-id': string
  'uploaded-by-user-id': string
}

export type EmptyClip = {
  isLoading: boolean
  clipId: string
}

export enum ClipLayout {
  highlight,
  slides,
}
