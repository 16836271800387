import { FlatList } from 'react-native'

import { Clip, GalleryItem } from '../../../types/Files'
import { TabPanel } from '../../../components/tabView'
import { TabProps } from '../../../types/Tab'
import ClipItem from '../../../components/ClipItem'
import { useFetchYourClips } from '../../../hooks/clip/useFetchClip'
import { useActiveOrgId } from '../../../context/UserContext'

type YourClipsProps = {
  tab: TabProps
  onPressClip: (clip: Clip) => void
}

const YourClips = (props: YourClipsProps) => {
  const { tab, onPressClip } = props

  const orgId = useActiveOrgId()
  const { data: items } = useFetchYourClips(orgId)

  const data = (items || []) as Clip[]
  return (
    <TabPanel key={`your_clips`} displayKey={tab.tabKey}>
      <FlatList
        data={data}
        extraData={data}
        numColumns={2}
        renderItem={({ item, index }) => (
          <ClipItem
            item={item}
            index={index}
            columns={2}
            onPressItem={(item: GalleryItem) => onPressClip(item as Clip)}
          />
        )}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item, index) => `sectionItem${item.recordKey}${index}`}
      />
    </TabPanel>
  )
}

export default YourClips
