import React, { useState } from 'react'

export type Screen = {
  key: string
  title?: string
  screen: React.FunctionComponent<any>
  props?: any
  onBack?: () => void
}

type ScreenStackContextType = {
  navigateTo: (screenKey: string, props?: any) => void
  replaceTo: (screenKey: string, props?: any) => void
  onBack: () => void
  onBackToTop?: () => void
  currentScreen?: Screen
  currentStack: Screen[]
}

type ScreenStackProviderProps = {
  children: React.ReactNode
  screenStack: Screen[]
}

const ScreenStackContext = React.createContext<
  ScreenStackContextType | undefined
>(undefined)

const ScreenStackContextProvider = ({
  children,
  screenStack,
}: ScreenStackProviderProps) => {
  const [activeScreenStack, setActiveScreenStack] = useState<Screen[]>(
    screenStack.length > 0 ? [screenStack[0]] : []
  )

  function navigateTo(screenKey: string, props?: any) {
    const newScreen = screenStack.find((screen) => screen.key === screenKey)
    if (newScreen) {
      if (props) {
        newScreen.props = props
      }
      const updatedStack = [...activeScreenStack, newScreen]
      setActiveScreenStack(updatedStack)
    } else {
      throw new Error('No specific screen found for given key')
    }
  }

  function replaceTo(screenKey: string, props?: any) {
    const newScreen = screenStack.find((screen) => screen.key === screenKey)
    if (newScreen) {
      if (props) {
        newScreen.props = props
      }
      setActiveScreenStack([newScreen])
    } else {
      throw new Error('No specific screen found for given key')
    }
  }

  function onBack() {
    if (activeScreenStack.length > 1) {
      activeScreenStack.pop()
      setActiveScreenStack([...activeScreenStack])
    }
  }

  function onBackToTop() {
    setActiveScreenStack(screenStack.length > 0 ? [screenStack[0]] : [])
  }

  return (
    <ScreenStackContext.Provider
      value={{
        navigateTo: navigateTo,
        onBack: onBack,
        onBackToTop: onBackToTop,
        currentScreen: activeScreenStack.at(-1),
        currentStack: activeScreenStack,
        replaceTo: replaceTo,
      }}
    >
      {children}
    </ScreenStackContext.Provider>
  )
}

export type ScreenNavigationProps = {
  navigateTo: (screenKey: string, props?: any) => void
  replaceTo: (screenKey: string, props?: any) => void
  onBack: () => void
  onBackToTop: () => void
  currentScreen?: Screen
  currentStack: Screen[]
}
const useNavigation = () => {
  const context = React.useContext(ScreenStackContext)
  return {
    navigateTo: context?.navigateTo,
    replaceTo: context?.replaceTo,
    onBack: context?.onBack,
    onBackToTop: context?.onBackToTop,
    currentScreen: context?.currentScreen,
    currentStack: context?.currentStack || [],
  } as ScreenNavigationProps
}

export { ScreenStackContextProvider, useNavigation }
