import { useState, useEffect } from 'react'
import { ScreenType } from '../types/Common'
import { Constants } from '../common'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

function getScreenTypeFromSize(screenWidth: number) {
  if (screenWidth >= Constants.BREAK_POINT) {
    return ScreenType.Desktop
  } else if (screenWidth >= 621 && screenWidth < Constants.BREAK_POINT) {
    return ScreenType.Tablet
  } else {
    return ScreenType.Mobile
  }
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  /*
  //TODO:This will add lot of processing to the site
  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions()
      setWindowDimensions(dimensions)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
*/
  return windowDimensions
}

export default function useScreenType(): ScreenType {
  const [screenType, setScreenType] = useState(
    getScreenTypeFromSize(getWindowDimensions().width)
  )

  /* 
  //TODO:This will add lot of processing to the site
  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions()
      setScreenType(dimensions.width)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  */

  return screenType
}

export { useScreenType, useWindowDimensions }
