import React from 'react'
import { SafeAreaView, StyleProp, ViewStyle } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { Navigation } from '@gotradie/gt-components'

import {
  ScreenStackContextProvider,
  useNavigation,
  Screen,
  ScreenNavigationProps,
} from '../context/ScreenStackContext'

const { styles } = StyleSheet.create({
  containerStyle: {
    flex: 1,
  },
})

type StackSidebarProps = {
  screenStack: Screen[]
  HeaderComponent?: React.ReactNode
  containerStyle?: StyleProp<ViewStyle>
  hideHeader?: boolean
} & typeof DefaultStackSidebarProps

const DefaultStackSidebarProps = {
  containerStyle: {},
}

const StackSideBar = (props: StackSidebarProps) => {
  const { HeaderComponent, containerStyle, hideHeader } = props
  const navigation = useNavigation()

  const ScreenComponent = navigation.currentScreen?.screen

  const getBackFunction = (navProps?: ScreenNavigationProps) => {
    const hasBack = navProps?.currentScreen?.onBack !== undefined
    const onBack = hasBack ? navProps?.currentScreen?.onBack : navProps?.onBack
    return {
      hasBack,
      onBack,
    }
  }

  const backProps = getBackFunction(navigation)
  return (
    <SafeAreaView style={[styles.containerStyle, containerStyle]}>
      {!hideHeader && !!HeaderComponent && HeaderComponent}
      {!hideHeader && !HeaderComponent && (
        <Navigation
          title={navigation.currentScreen?.title}
          back={backProps.hasBack || (navigation.currentStack || []).length > 1}
          onBack={backProps.onBack}
        />
      )}
      {ScreenComponent ? (
        <ScreenComponent {...navigation.currentScreen?.props} />
      ) : null}
    </SafeAreaView>
  )
}

const StackSidebarWrapper = (props: StackSidebarProps) => {
  const { screenStack } = props
  return (
    <ScreenStackContextProvider screenStack={screenStack}>
      <StackSideBar {...props} />
    </ScreenStackContextProvider>
  )
}

StackSidebarWrapper.defaultProps = DefaultStackSidebarProps

export default StackSidebarWrapper
