import React from 'react'
import { View, StyleProp, ViewStyle, TouchableOpacity } from 'react-native'
import StyleSheet from 'react-native-media-query'
import {
  AppColors,
  ComponentOptions,
  Icon,
  Text,
} from '@gotradie/gt-components'
import RightPanel from './RightPanel'
import { IconProps, NavigationProps } from '../types/Common'
import LinearGradient from 'react-native-linear-gradient'

const { styles } = StyleSheet.create({
  wrapperStyle: {
    flex: 1,
  },
  mainNavigationContainerStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
    display: 'none',
    '@media (max-width: 600px)': {
      display: 'flex',
    },
  },
  mainNavigationSubEleContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainScreenHeaderStyle: {
    flexDirection: 'row',
    paddingTop: ComponentOptions.SPACES.LARGEST,
    paddingBottom: ComponentOptions.SPACES.LARGE,
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      paddingTop: 0,
      paddingBottom: ComponentOptions.SPACES.MEDIUM,
    },
  },
  mainHeaderTitleContainerStyle: {
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    flex: 4,
    '@media (max-width: 600px)': {
      paddingBottom: ComponentOptions.SPACES.MEDIUM,
      width: '100%',
    },
  },
  mainHeaderRightElementContainerStyle: {
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    justifyContent: 'flex-end',
    '@media (max-width: 600px)': {
      width: '100%',
      paddingBottom: ComponentOptions.SPACES.MEDIUM,
    },
  },
  headerSubTitleStyle: {
    paddingTop: ComponentOptions.SPACES.SMALL,
  },
  contentWrapperStyle: {
    flexGrow: 1,
  },
  rightPanelContainerStyle: {
    width: 100,
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  containerStyle: {
    flexDirection: 'row',
    flex: 1,
  },
  containerMidStyle: {
    flex: 1,
  },
})

const DefaultHeaderSubElementProps = {
  icon: {
    name: 'ChevronLeft',
    size: 16,
    fill: AppColors.common.white,
    marginRight: ComponentOptions.SPACES.SMALL,
  },
  onPress: () => {},
  titleColor: AppColors.common.white,
}

type MainScreenNavSubElementProps = {
  title?: string
  icon?: IconProps
  onPress?: () => void
  titleColor?: string
}

const MainScreenNavigationSubElement = (
  props: MainScreenNavSubElementProps
) => {
  const { onPress, icon, title, titleColor } = props
  return (
    <TouchableOpacity
      onPress={onPress}
      style={styles.mainNavigationSubEleContainerStyle}
    >
      <Icon {...icon} />
      {title && (
        <Text body_small color={titleColor}>
          {title}
        </Text>
      )}
    </TouchableOpacity>
  )
}

type MainScreenNavigationProps = {
  leftElement?: MainScreenNavSubElementProps | React.ReactElement
  rightElement?: MainScreenNavSubElementProps | React.ReactElement
  containerStyle?: StyleProp<ViewStyle>
}

const MainScreenNavigation = (props: MainScreenNavigationProps) => {
  const { leftElement, containerStyle } = props
  return (
    <View style={[styles.mainNavigationContainerStyle, containerStyle]}>
      {leftElement && (
        <React.Fragment>
          {React.isValidElement(leftElement) && leftElement}
          {!React.isValidElement(leftElement) && (
            <MainScreenNavigationSubElement {...leftElement} />
          )}
        </React.Fragment>
      )}
    </View>
  )
}

type MainScreenHeaderProps = {
  title?: string
  subTitle?: string
  rightElement?: React.ReactElement
}

const MainScreenHeader = (props: MainScreenHeaderProps) => {
  const { title, subTitle, rightElement } = props
  return (
    <View style={styles.mainScreenHeaderStyle}>
      <View style={styles.mainHeaderTitleContainerStyle}>
        {title && (
          <Text h3 color={AppColors.common.white}>
            {title}
          </Text>
        )}
        {subTitle && (
          <Text
            body
            color={AppColors.common.subtitleText}
            StyleProps={styles.headerSubTitleStyle}
          >
            {subTitle}
          </Text>
        )}
      </View>
      {rightElement && React.isValidElement(rightElement) && (
        <View style={styles.mainHeaderRightElementContainerStyle}>
          {rightElement}
        </View>
      )}
    </View>
  )
}

type MainContentProps = {
  mainContentNavigation?: React.ReactNode
  mainContainerNavigationStyle?: StyleProp<ViewStyle>
  mainContentHeader?: MainScreenHeaderProps | React.ReactNode
  backButtonTitle?: string
  children?: React.ReactNode
  rightPanelContainerStyles?: StyleProp<ViewStyle>
  rightPanel?: React.ReactNode
  rightPanelNavigationProps?: NavigationProps
  onCloseRightPanelCallback?: () => void
  onCloseMainContentCallback?: () => void
  contentWrapperStyle?: StyleProp<ViewStyle>
} & typeof DefaultMainContentProps

const DefaultMainContentProps = {
  onCloseRightPanelCallback: () => {},
  contentWrapperStyle: {},
}

function MainContent({
  mainContentNavigation,
  mainContainerNavigationStyle,
  mainContentHeader,
  backButtonTitle,
  children,
  rightPanelContainerStyles,
  rightPanel,
  rightPanelNavigationProps,
  onCloseMainContentCallback,
  contentWrapperStyle,
}: MainContentProps) {
  return (
    <LinearGradient
      style={styles.wrapperStyle}
      colors={[AppColors.common.transparent, AppColors.common.dark]}
      locations={[0.5, 1]}
    >
      <View style={styles.containerStyle}>
        <View style={styles.containerMidStyle}>
          {/*Main content Navigation:Start */}
          {onCloseMainContentCallback &&
            (!mainContentNavigation ||
              !React.isValidElement(mainContentNavigation)) && (
              <MainScreenNavigation
                leftElement={{
                  ...DefaultHeaderSubElementProps,
                  title: backButtonTitle,
                  onPress: onCloseMainContentCallback,
                }}
                containerStyle={mainContainerNavigationStyle}
              />
            )}
          {mainContentNavigation &&
            React.isValidElement(mainContentNavigation) &&
            mainContentNavigation}
          {/*Main content Navigation:End */}

          {mainContentHeader &&
            React.isValidElement(mainContentHeader) &&
            mainContentHeader}
          {mainContentHeader && !React.isValidElement(mainContentHeader) && (
            <MainScreenHeader
              {...(mainContentHeader as MainScreenHeaderProps)}
            />
          )}

          <View style={[styles.contentWrapperStyle, contentWrapperStyle]}>
            {children}
          </View>
        </View>
        {rightPanel && (
          <RightPanel
            containerStyles={[
              styles.rightPanelContainerStyle,
              rightPanelContainerStyles,
            ]}
            navigationProps={rightPanelNavigationProps}
          >
            {rightPanel}
          </RightPanel>
        )}
      </View>
    </LinearGradient>
  )
}

MainContent.defaultProps = DefaultMainContentProps

export default MainContent
