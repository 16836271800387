export default {
  BREAK_POINT: 1024,
  TITLE: 'Tradie messenger | GoTradie',
  DYNAMODB_NONE_CHARACTER: 'DYNAMODB-NONE-CHARACTER',
  LOCAL_MESSAGE_SEQUENCE_START: 1000000,
  SCREEN_LAYOUT_DEFAULT_SIZES: {
    RIGHT_PANEL: 375,
    SIDE_BAR: 375,
  },
  DURATION: {
    SECOND: 1000,
    MINUTE: 60 * 1000,
    HOUR: 60 * 60 * 1000,
    DAY: 24 * 60 * 60 * 1000,
    WEEK: 7 * 24 * 60 * 60 * 1000,
  },
  ORGANIZATION_TYPES: {
    TRADE_ORG: 'trade-org',
    SEEKER_ORG: 'seeker-org',
    SUPPLIER_ORG: 'supplier-org',
  },
  IMAGE_FETCH_TYPE: {
    PHOTO: 'photo',
    VIDEO_THUMBNAIL: 'videoThumbnail',
    PROFILE: 'profile',
    CHAT_ICON: 'chatIcon',
  },
  USER_MESSAGE_CHAT_FILTERS: {
    TEAMS: 'teams',
    WORKSITES: 'worksites',
    CLIENTS: 'clients',
    GENERAL: 'general',
    THREADS: 'threads',
  },
  PAGE_SIZES: {
    THREAD_FETCH_BATCH_SIZE: 20,
    THREAD_SEARCH_BATCH_SIZE: 20,
    MESSAGE_FETCH_BATCH_SIZE: 30,
    MESSAGE_GALLERY_MEDIA_BATCH_SIZE: 20,
    CLIPS_FETCH_BATCH_SIZE: 50,
    NOTIFICATIONS: 20,
    MEMBERS: 100000, //since pagination not support
    CLIP_HIGHLIGHTS: 5,
  },
  THREAD_TYPE: {
    TEAMS: 'teams',
    GENERAL: 'general',
    CLIENTS: 'clients',
  },
  MESSAGE_MEDIA_TYPE: {
    PHOTO: 'photo',
    VIDEO: 'video',
    DOCUMENT: 'document',
    AUDIO: 'audio',
    BUSINESS_PROFILE: 'business-profile',
    CLIP: 'clip',
  },
  BOT_ID: 'autogen-bot-profile-id',
  BOT_NAME: 'Bot',
  MESSAGE_SEARCH_TYPES: {
    ALL_THREAD_SEARCH: 'all-thread-search',
    THREAD_SEARCH: 'thread-search',
  },
  MESSAGE_SEARCH_MATERIAL_TYPES: {
    PHOTO: 'photo',
    VIDEO: 'video',
    AUDIO: 'audio',
    DOCUMENT: 'document',
    TEXT: 'text',
  },
  THREAD_AFFILIATION_TYPE: {
    BUSINESS: 'business',
    USER: 'user',
    PROSPECTIVE_USER: 'prospective-user',
    PROSPECTIVE_BUSINESS_OWNER: 'prospective-business-owner',
  },
  USER_ACTIVE_ROLE: {
    userSignUpRole: 'user-signup-role',
    seekerRole: 'seeker-role',
    tradieSignUpRole: 'tradie-signup-role',
    tradieOwner: 'tradie-owner',
    tradieWorker: 'tradie-worker',
    tradieAdmin: 'tradie-admin',
    tradiePendingBusinessRole: 'tradie-pending-business-role',
    tradiePendingBusinessRejectedRole: 'tradie-pending-business-rejected-role',
    defaultOwner: 'default-owner',
  },
  USER_ROLE_TEMPLATES: {
    default_tradie_owner: 'default-owner',
  },
  GROUP_INVITE_MESSAGE_TYPE: {
    NEW_USER_INVITE: 'new-user-invite',
    NONE: 'none',
  },
  MESSAGE_ACTION_TYPE: {
    NONE: 'none',
    INVITE: 'invite-response',
  },
  INVITE_RESPONSE: {
    ACCEPT: 'accept',
    REJECT: 'reject',
  },
  MEMBER_REQUEST_STATUS: {
    PENDING: 'pending',
  },
  WEB_SOCKET_MESSAGE_ACTIONS: {
    SEND_MESSAGE: 'sendmessage',
    MARK_AS_READ: 'markasread',
    DELETE_MESSAGE: 'deletemessage',
    READ_EDIT_MESSAGE: 'readeditmessage',
    EDIT_MESSAGE: 'editmessage',
    LIVE_TYPING: 'livetyping',
    QUICK_RESPONSE: 'quickresponse',
    REMOVE_QUICK_RESPONSE: 'removequickresponse',
    KEEP_ALIVE: 'keepalive',
    NOTIFICATION: 'notification',
  },
  NOTIFICATION_TYPE: {
    MESSAGE: 'MESSAGE',
    SYSTEM_MESSAGE: 'SYSTEM-MESSAGE',
    SYSTEM_MESSAGE_WEB: 'SYSTEM-MESSAGE-WEB',
    SYSTEM_EVENT: 'SYSTEM-EVENT',
    HTML_MESSAGE: 'HTML-MESSAGE',
    URL_MESSAGE: 'URL-MESSAGE',
  },
  METADATA_MESSAGE_DEFAULT_TEXT: {
    BUSINESS_PROFILE: 'shared @business-profile@',
  },
  TIMEOUT: {
    DEFAULT: 500,
    MODAL_HIDE: 1000,
    TYPE_WAITING: 1500, // reduced per Sajith's request
    TYPE_WAITING_DEFAULT: 2000, // reduced per Sajith's request
    IMAGE_PROCESS_WAITING: 800,
    SEARCH_HOME_ANIMATION_PLACEHOLDER: 1000,
    SEARCH_HOME_ANIMATION_PLACEHOLDER_CHAR: 50,
    SEARCH_HOME_ANIMATION_PLACEHOLDER_REV: 100,
    MESSAGE_THREAD_TOOLBAR_ANIMATION: 200,
    MESSAGE_THREAD_SEND_ICON_ANIMATION: 50,
    MESSAGE_THREAD_LIVE_TYPING_ANIMATION: 300,
    MESSAGE_THREAD_LIVE_TYPING_USERS_DELETE: 7000,
    MESSAGE_THREAD_SEND_LIVE_TYPING_USERS: 4000,
    MESSAGE_FLOATING_ANIMATION: 5000,
    SET_VIDEO_TO_INITIAL_POSITION: 1000,
    FLASH_MESSAGE_WAITING: 3000,
    MEDIA_RELATED_MODAL_WAITING: 250,
    CLAIM_SUCCESS_WAIT_LOADING: 3000,
    WAIT_UNTIL_REDIRECT: 1000,
  },
  MESSAGE_SEARCH_OBJECT_LEVEL: {
    MESSAGE: 'message',
    THREAD: 'thread',
  },
  GALLERY_MEDIA_CATEGORY: {
    MEDIA: 'media',
    DOCS: 'docs',
    CLIPS: 'clips',
  },
  IMAGE_SIZES: {
    MESSAGE_BUBBLE: '240x210',
    AVATAR_LARGE: '150x150',
    AVATAR_SMALL: '20x20',
    STORY_THUMB: '170x235',
    STORY: 'story',
    GALLERY_THUMB: '125x125',
    REPLICA: 'replica',
  },
  CHAT_POSITION: {
    LEFT: 'left',
    RIGHT: 'right',
  },
  PROFILE_TYPE: {
    TRADIE: 'tradie',
    SEEKER: 'seeker',
  },
  MEMBER_TYPE: {
    MEMBER: 'member',
    PENDING_MEMBER: 'pendingMember',
  },
  FILE_UPLOAD_STATUS: {
    NOT_UPLOADED: 'not-uploaded',
    UPLOADING: 'uploading',
    UPLOADED: 'uploaded',
    ERROR: 'error',
  },
  MAX_UPLOAD_ITEM_COUNT: 10,
  MEDIA_UPLOAD_TYPES: {
    IMAGES: 'images',
    VIDEOS: 'videos',
    AUDIOS: 'audios',
    MOD: 'mod', // modified file,
    PROFILE: 'profile',
    CHAT_ICONS: 'chat-icons',
    LICENSE: 'license',
  },
  MIME_TYPES: {
    PNG: 'image/png',
    HEIC: 'image/heic',
    HEIF: 'image/heif',
    JPG: 'image/jpg',
    JPEG: 'image/jpeg',
  },
  CLIP_ACCEPTED_MIME_TYPES: [
    'image/webp',
    'image/png',
    'image/jpg',
    'image/jpeg',
    'video/mp4',
  ],
  UPLOAD_LIMITS: {
    CLIP_SLIDE_BULK_UPLOADING_COUNT: 40,
    FILES_BULK_UPLOADING_COUNT: 10,
  },
  MEDIA_ORIGIN_TYPES: {
    THREAD: 'thread',
    THREAD_SETTINGS: 'thread-settings',
    BUSINESS_PROFILE: 'business-profile',
    USER_PROFILE: 'user-profile',
  },
  MAX_CHARACTERS_INPUT: {
    SHARE_CLIP_DES: 124,
  },
  MEDIA_CATEGORY_TYPES: {
    MEDIA: 'media',
    DOCS: 'docs',
    CLIP: 'clip',
    THREAD_SETTINGS: 'thread-settings',
    BUSINESS_PROFILE: 'business-profile',
    USER_PROFILE: 'user-profile',
  },
  THREAD_FILTER_OPTIONS: {
    UNREAD_ONLY: 'unread-only',
    CATEGORISE_BY_FILTER: 'categorise-by-filter',
    CATEGORISE_BY_FILTER_INCLUDE_TEAMS: 'categorise-by-filter-include-teams',
  },
  CHAT_STATUS: {
    IS_LOADING: 'loading',
    SEND: 'send',
  },
  MINI_PROFILE_TYPE: {
    BUSINESS: 'business',
    TRADIE: 'tradie',
    SEEKER: 'seeker',
  },
  MAX_SHOW_COUNT: {
    MINI_PROFILE_SERVICES: 3,
    BUSINESS_PROFILE_SERVICES: 5,
  },
  TRADIE_TYPE: {
    BUSINESS_OWNER: 'business_owner',
    WORKER: 'worker',
    ADMIN: 'Foreman',
  },
  NOTIFICATION_SCENARIO: {
    OWNER_ACCEPTED_REQUEST_NOTIFY_WORKER:
      'owner-accepted-request-notify-worker',
    OWNER_REJECTED_REQUEST_NOTIFY_WORKER:
      'owner-rejected-request-notify-worker',
    REMOVED_MEMBER_FROM_GROUP: 'removed-member-from-group',
    NEW_INVITATION_TO_JOIN_BUSINESS: 'new-request-to-join-business',
    NEW_INVITATION_TO_JOIN_GROUP: 'new-invitation-to-join-group',
    APP_UPDATE_AVAILABLE: 'app-update-available',
    LICENCE_EXPIRY_NOTIFICATION_TO_USER: 'license-expiry-notification-to-user',
    BUSINESS_WITHOUT_LICENSE_NOTIFICATION_TO_OWNER:
      'business-without-license-notification-to-owner',
    CLIENT_ACCEPTED_REQUEST_JOIN_CHAT: 'client-accepted-request-join-chat',
    NEW_MEMBER_JOINED_TEAM: 'new-member-joined-team',
    INVITED_MEMBER_SIGNUP_NOTIFY_OWNER: 'invited-member-signup-notify-owner',
    OWNER_INVITED_A_NEW_USER_TO_BUSINESS:
      'owner-invited-a-new-user-to-business',
    MEMBER_ACCEPTED_GROUP_INVITE: 'member-accepted-group-invite',
    MEMBER_REJECTED_GROUP_INVITE: 'member-rejected-group-invite',
    MEMBER_LEFT_FROM_GROUP: 'member-left-from-group',
    INFORM_MEMBER_THAT_USER_INVITED: 'inform-members-that-user-invited',
  },
  THREAD_MEMBER_STATUS: {
    ACTIVE: 'active',
    PENDING: 'pending',
  },
  MESSAGE_BUBBLE_CONST: {
    MAX_REACTIONS_DISPLAY: 2,
  },
  INVITE_SCREEN_TYPE: {
    INVITE_TO_BUSINESS: 'inviteToBusiness',
    INVITE_TO_CLIENT_CHAT: 'inviteToClientChat',
    INVITE_TO_WORKSITE_CHAT: 'inviteToWorksiteChat',
  },
  BUSINESS_PROFILE_EDIT_LIST: [
    {
      id: 'business_logo_edit',
      title: 'Change business logo',
      subTitle: 'Upload new logo or photo',
      key: 'businessLogoEdit',
    },
    {
      id: 'basic_details_edit',
      title: 'Basic details',
      subTitle: 'Change business name and trade type',
      key: 'businessNameEdit',
    },
    {
      id: 'manage_clips',
      title: 'Manage clips',
      subTitle: 'Change order of your clips',
      key: 'businessManageClips',
    },
    {
      id: 'trade_services_edit',
      title: 'Trade services',
      subTitle: 'Edit and manage your trade services',
      key: 'businessTradeServicesEdit',
    },
    {
      id: 'trade_service_area_edit',
      title: 'Service area',
      subTitle: 'Add or edit your service area',
      key: 'businessServiceAreaEdit',
    },
    {
      id: 'trade_rates_edit',
      title: 'Rates & Fees',
      subTitle: 'Add or edit your rates & fees',
      key: 'businessRatesEdit',
    },
    {
      id: 'licence_edit',
      title: 'Licence details',
      subTitle: 'Update or remove your licence',
      key: 'businessLicenceImageUploadEdit',
    },
    {
      id: 'business_address_edit',
      title: 'Business HQ',
      subTitle: 'Update your business address',
      key: 'businessAddressEdit',
    },
    {
      id: 'business_ABN_edit',
      title: 'ABN details',
      subTitle: 'Chnage ABN number',
      key: 'businessABNEdit',
    },
    {
      id: 'handle_edit',
      title: 'Change GoTradie handle',
      subTitle: 'Update or change your GoTradie handle',
      key: 'businessHandleEdit',
    },
  ],
  POPULAR_TRADES: [
    {
      id: 1,
      tradeHash: 'ff3963b1-25cd-4523-8649-05066bc14336',
      tradeName: 'Carpenter',
      tradeIcon: 'Carpenter',
    },
    {
      id: 2,
      tradeHash: '2b8cd333-d2af-4908-a157-f179ec4e00d2',
      tradeName: 'Plumber',
      tradeIcon: 'Plumber',
    },
    {
      id: 3,
      tradeHash: 'e98a59c6-114c-4afa-914b-a61134fd149e',
      tradeName: 'Electrician',
      tradeIcon: 'Electrician',
    },
    {
      id: 4,
      tradeHash: '609106f8-d967-41ae-8cc8-b95e15182419',
      tradeName: 'Bricklayer',
      tradeIcon: 'Bricky',
    },
    {
      id: 5,
      tradeHash: 'c47bab6d-0c6c-44ce-a017-ea27072267bf',
      tradeName: 'Landscaper',
      tradeIcon: 'Landscaper',
    },
    {
      id: 6,
      tradeHash: 'dc42833b-d158-47c8-a76b-4407c97d5e38',
      tradeName: 'Builder',
      tradeIcon: 'Builder',
    },
  ],
  RENOVATION_TRADES: [
    {
      id: 1,
      tradeHash: '3a3271a6-c5bb-4be0-b0dc-1dada7ce9198',
      tradeName: 'Tiler',
      tradeIcon: 'Tiler',
    },
    {
      id: 2,
      tradeHash: 'd3adb6f2-ed4e-4865-b45c-21bb6292affe',
      tradeName: 'Cabinet maker',
      tradeIcon: 'CabinetMaker',
    },
    {
      id: 3,
      tradeHash: 'dd7d1834-a395-4f3a-8df9-1ca4e8f2e2f3',
      tradeName: 'Wallpaperer',
      tradeIcon: 'Wallpaperer',
    },
  ],
  SEARCH_RADIOUS_OPTIONS: [
    {
      name: '0 - 10km from me',
      value: 10000,
      selected: true, // Default selected radius
    },
    {
      name: '0 - 40km from me',
      value: 40000,
      selected: false,
    },
    {
      name: '0 - 100km from me',
      value: 100000,
      selected: false,
    },
  ],
  INVITE_REQ_TYPES: {
    REQ_BY_USER: 'requestedByUser',
    REQ_BY_BUSINESS_OWNER: 'requestedByBusinessOwner',
  },
  AUS_STATES: ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'NT', 'ACT', 'TAS'],
  HEROKU_APP_BASE_URL: 'https://cors-anywhere.herokuapp.com/', // TODO: Need to implement our own proxy (until that using this)
}
