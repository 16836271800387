import { useEffect, useRef } from 'react'
import { Image, View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import {
  ComponentOptions,
  Spacer,
  Text,
  AppColors,
} from '@gotradie/gt-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Nav from '../navigation/NavigationKeys'
import Wrapper from '../components/Wrapper'
import OrgRoleSelection from '../containers/login/OrgRoleSelection'
import { useUserContext } from '../context/UserContext'
import { Util } from '../common'

const { styles } = StyleSheet.create({
  wrapperOuter: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '75rem',
    marginHorizontal: 'auto',
  },
  wrapperSpacer: {
    paddingVertical: ComponentOptions.SPACES.LARGER * 4,
    '@media (max-width: 1020px)': {
      paddingVertical: ComponentOptions.SPACES.LARGER,
    },
    '@media (max-width: 375px)': {
      paddingVertical: 0,
    },
  },
  wrapperStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sidebarWrapper: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '33.33333%',
    maxWidth: '33.33333%',
    '@media (max-width: 1024px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  mainWrapper: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '66.66667%',
    maxWidth: '66.66667%',
    alignItems: 'flex-start',
    paddingStart: ComponentOptions.SPACES.LARGE * 8,
    '@media (max-width: 1024px)': {
      flexBasis: '100%',
      maxWidth: '100%',
      paddingStart: 0,
    },
  },
  logoStyle: {
    width: '600px',
    height: '800px',

    '@media (max-width: 1024px)': {
      width: '335px',
      height: '420px',
    },
  },
  footerStyle: {
    marginVertical: ComponentOptions.SPACES.MEDIUM,
  },
})

const RoleSelectionPage = () => {
  const { activeRole, isAuthenticated } = useUserContext()
  const dateRef = useRef(new Date())
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    handleNavigation()
  }, [activeRole])

  const onOrgSelectionCompletion = () => {
    goToHome()
  }

  const handleNavigation = () => {
    if (activeRole !== null && isAuthenticated) {
      goToHome()
    }
  }

  const goToHome = () => {
    const redirect = searchParams.get('redirect')
    if (redirect !== null) {
      const url = `/${Util.parseURL(redirect)}`
      navigate(url)
    } else {
      navigate(Nav.HOME)
    }
  }

  const onAddNewOrgRole = () => {
    navigate(Nav.ADD_NEW_ACCOUNT)
  }

  return (
    <Wrapper>
      <View style={styles.wrapperOuter}>
        <View>
          <Spacer size={ComponentOptions.SPACES.LARGE * 2} />
        </View>
      </View>
      <View style={styles.wrapperOuter}>
        <View
          style={[
            styles.wrapperStyle,
            styles.sidebarWrapper,
            styles.wrapperSpacer,
          ]}
        >
          <OrgRoleSelection
            showAddNew={true}
            onCompletionCallback={onOrgSelectionCompletion}
            onAddNewOrgRole={onAddNewOrgRole}
          />
        </View>
        <View style={[styles.wrapperStyle, styles.mainWrapper]}>
          <Image
            source={require('../images/signin-hero.png')}
            style={styles.logoStyle}
            resizeMode={'contain'}
          />
        </View>
      </View>
      <View style={styles.wrapperOuter}>
        <View style={styles.footerStyle}>
          <Text small color={AppColors.common.white}>
            {`© ${dateRef.current.getFullYear()} by GoTradie™`}
          </Text>
        </View>
      </View>
    </Wrapper>
  )
}

export default RoleSelectionPage
