import { useMutation } from 'react-query'
import { getAxios } from '../../common'
import { ServerError } from '../../types/Common'

const api = getAxios(true)

/*====================== Invite to magic link ================================*/
type inviteMagicLinkParam = {
  phoneNumber: string
  magicToken: string
}

const inviteMagicLink = async (params: inviteMagicLinkParam) => {
  return await api.post(`/threads/groups/invites/magiclink`, {
    'phone-number': params.phoneNumber,
    'magic-token': params.magicToken,
  })
}

export const useInviteMagicLink = (
  onSuccess: (data: any, variables: any) => void,
  onErrorCallback: (error: ServerError, variables: any) => void
) => {
  return useMutation(inviteMagicLink, {
    onSuccess,
    onError: (error: any, variables: any) => {
      onErrorCallback(error?.response?.data as ServerError, variables)
    },
  })
}
