export interface AppVersion {
  'latest-ios-app-version': string
  'latest-andorid-app-version': string
  'deprecated-andorid-app-versions': string
  'deprecated-ios-app-versions': string
}

export interface ServerError {
  'error-code'?: string
  'error-details'?: string
  message?: string
  type?: string
  code?: string
}

export interface ActiveRole {
  'role-type'?: string
  'org-id'?: string
  'user-id'?: string
  'effective-user-id': string
}

export type IconProps = {
  name: string
  fill?: string
  size?: number
  marginRight?: number
}

export type NavigationProps = {
  back: boolean
  onBack?: () => void
  title?: string
  subTitle?: string
  leftIcon?: IconProps | React.ReactNode
  rightIcon?: IconProps | React.ReactNode
  navTransparent?: boolean
}

export type Coordinates = {
  latitude: number
  longitude: number
}

export type Location = {
  streetAddress: string
  address: string
  streetNumber: string
  coordinates: Coordinates
  name: string
  suburb: string
  state: string
  postalCode: string
  subPremise: string
  route: string
}

export const enum ScreenType {
  Desktop,
  Tablet,
  Mobile,
}

export type LoadingElementType = {
  isLoading: boolean
}

export const enum Theme {
  dark = 'dark',
  light = 'light',
}

export const enum PermissionStatus {
  granted = 'granted',
  prompt = 'prompt',
  denied = 'denied',
  notSupport = 'notSupport',
}

export type LocationCoordinates = {
  latitude: number
  longitude: number
  accuracy: number
}

export enum DefaultContentType {
  default,
  chat,
  clips,
}
