import { useEffect, useState } from 'react'
import { Image as RNImage } from 'react-native'
import { fetchMedia } from '../../common'
import {
  MediaNotAvailableError,
  MediaTypeNotSupportedError,
} from '../../common/Errors'

type ImageProps = {
  s3Key?: string
  size?: string
  source?: string
  imageFetchType?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imageProps: any
  mediaNotAvailablePlaceholderUri?: string
  mediaNotSupportedPlaceholderUri?: string
}

const Image = ({
  s3Key,
  size,
  source,
  imageProps,
  mediaNotAvailablePlaceholderUri,
  mediaNotSupportedPlaceholderUri,
}: ImageProps) => {
  const [imageUri, setImageUri] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!!s3Key && s3Key.length > 0 && !!size) {
      fetchMedia(s3Key, size)
        .then((uri) => {
          if (uri) {
            setImageUri(uri)
          }
        })
        .catch((error) => {
          if (error instanceof MediaTypeNotSupportedError) {
            setImageUri(
              mediaNotSupportedPlaceholderUri ||
                require('../../images/broken-image.png')
            )
          } else if (error instanceof MediaNotAvailableError) {
            setImageUri(
              mediaNotAvailablePlaceholderUri ||
                require('../../images/broken-media.png')
            )
          } else {
            setImageUri(undefined)
          }
        })
    }
  }, [s3Key])

  useEffect(() => {
    if (!!source) {
      setImageUri(source)
    }
  }, [source])

  return !!imageUri ? (
    <RNImage
      {...imageProps}
      source={{ uri: imageUri }}
      dataSet={{ id: s3Key }}
    />
  ) : null
}

export default Image
