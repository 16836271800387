import { useQuery } from 'react-query'
import { QueryKeys } from '../../common'
import { Address, Place } from '../../types/Business'
import axios from 'axios'

const fetchPlaces = async (query: string) => {
  const url = `${process.env.PROXY_URL}${encodeURIComponent(
    `https://maps.googleapis.com/maps/api/place/autocomplete/json?components=country:AU|country:LK&input=${query}&key=${process.env.GOOGLE_API_KEY}`
  )}`

  return await axios.request({
    method: 'get',
    url: url,
  })
}
const fetchPlaceById = async (placeId: string) => {
  const url = `${process.env.PROXY_URL}${encodeURIComponent(
    `https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&key=${process.env.GOOGLE_API_KEY}&fields=formatted_address,geometry,name,address_component&language=en`
  )}`
  return await axios.request({
    method: 'get',
    url: url,
  })
}

export const useFindPlaces = (query: string, enabled = false) => {
  return useQuery([QueryKeys.LOCATIONS], () => fetchPlaces(query), {
    enabled,
    select: (data) => {
      const address = data?.data
      if (address?.predictions) {
        return address.predictions.map((i: any) => {
          return {
            place: i.description,
            placeId: i.place_id,
          } as Place
        })
      }
      return [] as Place[]
    },
  })
}

export const useGetSpecificPlace = (
  placeId: string,
  onSuccessCallback: (address?: Address) => void,
  enabled = false
) => {
  return useQuery([QueryKeys.PLACE_DETAILS], () => fetchPlaceById(placeId), {
    enabled,
    select: (data) => {
      const address = data?.data?.result
      if (address?.address_components) {
        return getAddressObjectFromAddressComponents(address)
      }
      return undefined
    },
    onSuccess(data) {
      onSuccessCallback(data)
    },
  })
}

export function getAddressComponent(
  addressComponents: any,
  attribute: string,
  selectAttribute = 'short_name'
) {
  let v = ''
  const found = addressComponents.find((element: any) => {
    const type = element.types
    if (attribute) {
      return type.find((e: any) => e === attribute)
    } else {
      return undefined
    }
  })

  if (found !== undefined && found[selectAttribute]) {
    v = found[selectAttribute]
  }
  return v
}

function getAddressObjectFromAddressComponents(result: any) {
  const address = result['formatted_address'] || ''

  const streetNumber =
    getAddressComponent(result.address_components, 'street_number') || ''
  const route =
    getAddressComponent(result.address_components, 'route', 'long_name') || ''
  const state =
    getAddressComponent(
      result.address_components,
      'administrative_area_level_1'
    ) || ''
  const suburb =
    getAddressComponent(result.address_components, 'locality') || ''
  const postalCode =
    getAddressComponent(result.address_components, 'postal_code') || ''
  const subpremise =
    getAddressComponent(result.address_components, 'subpremise') || ''

  const streetAddress = `${streetNumber} ${route}`.trim() || ''
  const location = result.geometry.location
  const latitude = location.lat || ''
  const longitude = location.lng || ''
  const name = result.name ? result.name : streetAddress

  return {
    streetNumber,
    cordinates: { latitude, longitude },
    address,
    route,
    suburb,
    state,
    postalCode,
    subpremise,
    streetAddress,
    name,
  } as Address
}
