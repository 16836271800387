import { AxiosError } from 'axios'
import { MagicLink } from './../../types/Invitation'
import { useQuery } from 'react-query'
import { QueryKeys, getAxios } from '../../common'
import { Invitation, InvitationType } from '../../types/Invitation'
import { ServerError } from '../../types/Common'
import { dtoToThread } from '../message/useFetchThread'

const apiNoAuth = getAxios(false)

type InvitationParam = {
  id: string
  invitationType?: InvitationType
}
const fetchInvitation = async ({ id, invitationType }: InvitationParam) => {
  let url = `signup/validateinvite?invite-id=${id}`
  if (invitationType) {
    url += `&invite-type=${invitationType}`
  }
  return await apiNoAuth.get(url)
}

export const useFetchInvitation = (
  req: InvitationParam,
  enabled = true,
  onErrorCallback?: (x: ServerError) => void
) => {
  return useQuery([QueryKeys.INVITATION, req.id], () => fetchInvitation(req), {
    initialData: undefined,
    enabled,
    retry: 2,
    onError: (error: AxiosError) => {
      onErrorCallback?.(error?.response?.data as ServerError)
    },
    select: (data) => {
      const invitation = data?.data?.['invitation-details']

      if (invitation) {
        if (req?.invitationType) {
          invitation['invitation-type'] = req?.invitationType
        }
        return dtoToInvitation(invitation)
      }
      return undefined
    },
  })
}

// Data convertors
function dtoToInvitation(response: any) {
  let invitation = {} as Invitation
  invitation.invitationType = response['invitation-type']
  if (invitation.invitationType === InvitationType.MAGIC_LINK) {
    invitation = dtoToInvitationMagicLink(invitation, response)
  }
  return invitation
}

function dtoToInvitationMagicLink(
  invitation: MagicLink,
  response: any
): MagicLink {
  const metaData = response?.metadata['thread'] || null
  invitation.magicToken = response['magic-token']
  invitation.showHistory = response['show-history']
  invitation.threadId = response['thread-id']
  invitation.threadInfo = dtoToThread(metaData)
  invitation.worksiteInfo = {
    worksiteName: metaData['group-name'],
    worksiteLogoUrl: metaData['group-chat-icon'],
  }
  return invitation
}
