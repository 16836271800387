import React from 'react'
import Lottie from 'lottie-react-web'

type LottieAnimationView = {
  name: string
  loop: boolean
  width: number | string
  height?: number
  autoplay: boolean
}

const LottieAnimationWebView = (props: LottieAnimationView) => {
  const { loop, autoplay, name, width, height } = props

  const getAnimationList = (name: string) => {
    switch (name) {
      case 'Loader':
        return require('./animations/animation_loader.json')
      case 'Logo':
        return require('./animations/animation_logo.json')
      case 'ScanLicence':
        return require('./animations/animation_scan_licence.json')
      case 'MessageLoader':
        return require('./animations/animation_message_loader.json')
      case 'SkeletonLoaderHome':
        return require('./animations/animation_skeleton_home.json')
      case 'LiveTyping':
        require('./animations/animation_live_typing.json')
      case 'AudioRecording':
        require('./animations/animation_audio_recording.json')
      case 'InviteWidget':
        return require('./animations/animation_invite_widget.json')
      default:
        break
    }
  }

  return (
    <Lottie
      options={{
        animationData: getAnimationList(name),
        loop,
        autoplay,
      }}
      width={width}
      height={height}
    />
  )
}

export default LottieAnimationWebView
