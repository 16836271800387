import { useParams } from 'react-router-dom'
import ClipPreviewContainer from '../containers/clip/ClipPreviewContainer'
import SignUpLayout from '../layout/SignUpLayout'

const ClipView = () => {
  const params = useParams()
  return (
    <SignUpLayout>
      <ClipPreviewContainer clipId={params?.clipId} />
    </SignUpLayout>
  )
}

export default ClipView
