import { useActiveOrgId, useActiveThreadId } from '../context/UserContext'
import {
  FirebaseNotification,
  NotificationMetadata,
  Notification,
} from '../types/Notification'
import Constants from './Constants'

function displayNotification(payload: FirebaseNotification) {
  const notification = payload?.notification
  const metadata = getNotificationMetadata(payload)
  if (shouldShowNotification(payload)) {
    const id = findNotificationId(
      payload?.data?.id,
      payload.data.type,
      metadata
    )
    const title = getNotificationTitle(metadata, notification)
    const subtitle = getNotificationSubtitle(metadata, notification)
    const body = getNotificationBody(metadata, notification)

    return <></>
  }
  return null
}

function getNotificationTitle(
  metadata: NotificationMetadata,
  notification: Notification
) {
  if (notification.title) {
    return notification.title
  } else if (metadata.title) {
    return metadata.title
  } else {
    return 'GoTradie'
  }
}

function getNotificationSubtitle(
  metadata: NotificationMetadata,
  notification: Notification
) {
  if (notification.subtitle) {
    return notification.subtitle
  } else if (metadata.subtitle) {
    return metadata.subtitle
  } else if (metadata['worksite-name']) {
    return metadata['worksite-name']
  } else {
    return undefined
  }
}

function getNotificationBody(
  metadata: NotificationMetadata,
  notification: Notification
) {
  if (notification.body) {
    return notification.body
  } else if (metadata.body) {
    return metadata.body
  } else {
    return ''
  }
}

function getNotificationMetadata(payload: FirebaseNotification) {
  const metadataString = payload?.data?.metadata || '{}'
  const metadata = JSON.parse(metadataString)
  return metadata
}

function findNotificationId(
  defaultId: string,
  notificationType: string,
  metadata: NotificationMetadata
) {
  if (metadata) {
    if (notificationType === Constants.NOTIFICATION_TYPE.MESSAGE) {
      return metadata['thread-id']
    }
  }
  return defaultId
}

function findNotificationOrgId(metadata: NotificationMetadata) {
  const businessId = metadata['business-id']
  const orgId = metadata['org-id']
  if (businessId) {
    return businessId
  } else if (orgId) {
    return orgId
  } else {
    return null
  }
}

function findNotificationScenario(payload: FirebaseNotification) {
  const metadata = getNotificationMetadata(payload)
  return metadata?.['notification-scenario'] || null
}

function shouldShowNotification(payload: FirebaseNotification) {
  const metadata = getNotificationMetadata(payload)
  const notificationOrgId = findNotificationOrgId(metadata)
  const activeOrgId = useActiveOrgId()
  const activeThreadId = useActiveThreadId()
  if (!notificationOrgId) {
    return true // if no org id then it's a general notification and should show all times
  } else if (notificationOrgId === activeOrgId) {
    if (payload.data.type === Constants.NOTIFICATION_TYPE.MESSAGE) {
      const threadId = metadata['thread-id']
      return threadId !== activeThreadId
    } else {
      return true
    }
  } else {
    return false
  }
}

export default {
  shouldShowNotification,
  displayNotification,
  getNotificationMetadata,
  findNotificationScenario,
}
