import { View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { useFetchClip } from '../../../hooks/clip/useFetchClip'
import { ClipOriginType } from '../../../types/Files'
import ClipSubmission from '../../clipCreation/ClipSubmission'

const { styles } = StyleSheet.create({
  wrapperStyle: {
    flex: 1,
  },
})

type ClipRightPanelEditProps = {
  clipId: string
  onPressClose: () => void
}

const ClipRightPanelEdit = (props: ClipRightPanelEditProps) => {
  const { clipId, onPressClose } = props
  const { data: clip } = useFetchClip(clipId)
  return (
    <View style={styles.wrapperStyle}>
      {clip ? (
        <ClipSubmission
          selectedMedia={[]}
          onClose={onPressClose}
          clipOriginType={ClipOriginType.THREAD}
          isEmptyClipCreation={false}
          isEditMode={true}
          clipObj={clip}
          showNavigation={true}
        />
      ) : null}
    </View>
  )
}

export default ClipRightPanelEdit
