import { TradieType } from './SignUp'
import { Thread } from './Thread'
export const enum InvitationType {
  BUSINESS = 'business',
  CLIENT_CHAT = 'client',
  MAGIC_LINK = 'magic-link',
}

//TODO:implement other invitation types eg: Business Invite, client chat
type WorksiteInfo = {
  worksiteName: string
  worksiteLogoUrl: string
}

export interface Invitation {
  invitationType: InvitationType
}

export interface MagicLink extends Invitation {
  magicToken?: string
  worksiteInfo?: WorksiteInfo
  showHistory?: boolean
  threadId?: string
  threadInfo?: Thread
}

export type InvitationResultProps = {
  invitationId: string
  invitationType: InvitationType
  mobileNumber?: string
  password?: string
  firstName?: string
  lastName?: string
  email?: string
  businessId?: string
  userId?: string
  roleType?: string
  effectiveUserId?: string
}

export type Step1MobileNumberProps = {
  onSuccess: (result: InvitationResultProps) => void
  onPressImBusinessOwner?: () => void
  onMobileNumberExists: (result: InvitationResultProps) => void
  data: InvitationResultProps
  userId?: string
  magicLink: MagicLink
}

export type Step2OTPProps = {
  onSuccess: (result: InvitationResultProps) => void
  onShowPreviousPage: () => void
  data: InvitationResultProps
}

export type Step3BasicDetailsProps = {
  onSuccess: (result: InvitationResultProps) => void
  data: InvitationResultProps
}
export type Step5BusinessOwnerProps = {
  onSuccess: (result: InvitationResultProps) => void
  data: InvitationResultProps
  onPressBack: () => void
}
export type Step5WorkerProps = {
  onSuccess: (result: InvitationResultProps) => void
  data: InvitationResultProps
  onPressBack: () => void
}

export type StepExistingAccountProps = {
  onAccept: (code: string) => void
  onCancel: () => void
  data: MagicLink
  isLoading: boolean
}
