import React, { useRef } from 'react'
import { SafeAreaView, ScrollView, View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import {
  GoTradieLogo,
  ComponentOptions,
  AppColors,
  MessageNote,
} from '@gotradie/gt-components'

const { styles } = StyleSheet.create({
  wrapperStyle: {
    flex: 1,
  },
  layoutMidStyle: {
    flexGrow: 8,
    alignItems: 'center',
  },
  layoutBottomStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingVertical: ComponentOptions.SPACES.LARGE,
  },
  logoStyle: {
    paddingTop: ComponentOptions.SPACES.LARGEST,
    paddingBottom: ComponentOptions.SPACES.LARGE,
  },
})

type Props = {
  children: React.ReactNode
}

const SignUpLayout = (props: Props) => {
  const { children } = props
  const dateRef = useRef(new Date())

  const onPressContactUs = () => {}

  return (
    <SafeAreaView style={styles.wrapperStyle}>
      <View style={styles.layoutMidStyle}>
        <GoTradieLogo width={168} height={20} style={styles.logoStyle} />
        {children}
      </View>
      <View style={styles.layoutBottomStyle}>
        <MessageNote
          arMessages={[
            {
              title: `© ${dateRef.current.getFullYear()} by GoTradie™`,
              color: AppColors.common.white,
            },
            {
              action: () => onPressContactUs(),
              color: AppColors.common.white,
              title: 'Contact us',
              paddingLeft: ComponentOptions.SPACES.SMALL,
            },
          ]}
          paddingHorizontal={0}
          paddingVertical={0}
        />
      </View>
    </SafeAreaView>
  )
}

export default SignUpLayout
