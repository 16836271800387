import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { AuthUtil, StorageUtil } from '../common'
import { ActiveRole } from '../types/Common'
import { themes } from '@gotradie/gt-components'
import { setUserProperties, getAnalytics } from 'firebase/analytics'
import { logEvent } from '../analytics'

type UserContextType = {
  isAuthenticated: boolean
  setAuthenticated: (state: boolean) => void
  activeTheme: string
  setActiveTheme: (themeId: string) => void
  activeRole: ActiveRole | null
  updateActiveRole: (role: ActiveRole) => void
  removeActiveRole: () => void
  activeThreadId: string | null
  setActiveThreadId: (state: string) => void
  removeActiveThreadId: () => void
}

type UserProviderProps = {
  children: React.ReactNode
}

const UserContext = React.createContext<UserContextType | undefined>(undefined)

const UserContextProvider = ({ children }: UserProviderProps) => {
  const [currentlyActiveThreadId, setCurrentlyActiveThreadId] = useState<
    string | null
  >(null)
  const [authenticated, changeAuthenticated] = useState(false)
  const [theme, changeTheme] = useState(themes.dark)
  const [activeRole, setActiveRole] = useState<ActiveRole | null>(null)

  const queryClient = useQueryClient()

  useEffect(() => {
    async function fetchActiveUserRole() {
      const activeRole = await StorageUtil.getActiveRole()
      setActiveRole(activeRole)
    }

    fetchActiveUserRole()
  }, [])

  useEffect(() => {
    async function updateActiveRole() {
      if (activeRole) {
        await StorageUtil.setActiveRole(activeRole)
      } else {
        await StorageUtil.removeActiveRole()
      }
    }

    updateActiveRole()
  }, [activeRole])

  return (
    <UserContext.Provider
      value={{
        isAuthenticated: authenticated,
        setAuthenticated: async (state: boolean) => {
          changeAuthenticated(state)
          if (!state) {
            await AuthUtil.logoutSession()

            const analytics = getAnalytics()

            setUserProperties(analytics, {
              user_id: '',
              phone_number: '',
              org_id: '',
            })
            logEvent('user_logout')
            setActiveRole(null)
            queryClient.clear()
          }
        },
        activeTheme: theme,
        setActiveTheme: (themeId: string) => {
          changeTheme(themeId)
        },
        activeRole: activeRole,
        updateActiveRole(role) {
          setActiveRole(role)
        },
        removeActiveRole() {
          setActiveRole(null)
        },
        activeThreadId: currentlyActiveThreadId,
        setActiveThreadId: (threadId: string) => {
          setCurrentlyActiveThreadId(threadId)
        },
        removeActiveThreadId: () => {
          setCurrentlyActiveThreadId(null)
        },
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

const useUserContext = () => {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}

const useActiveUserId = () => {
  const context = React.useContext(UserContext)
  return context?.activeRole?.['user-id'] || ''
}

const useActiveOrgId = () => {
  const context = React.useContext(UserContext)
  return context?.activeRole?.['org-id'] || ''
}

const useActiveThreadId = () => {
  const context = React.useContext(UserContext)
  return context?.activeThreadId
}

const useActiveRoleType = () => {
  const context = React.useContext(UserContext)
  return context?.activeRole?.['role-type'] || ''
}

export {
  UserContextProvider,
  useUserContext,
  useActiveOrgId,
  useActiveUserId,
  useActiveThreadId,
  useActiveRoleType,
}
