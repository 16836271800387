import { useMutation, useQueryClient } from 'react-query'
import { Constants, getAxios, MessageUtils, QueryKeys } from '../../common'
import { useActiveOrgId } from '../../context/UserContext'
import { GalleryTab } from '../../types/Files'
const api = getAxios(true)

//-----------------Send Messages-----------------
type SendMessageProps = {
  threadId: string
  message: any
  threadFilter?: string
  isClipCreation?: boolean
}

const sendMessageToThread = async ({ threadId, message }: SendMessageProps) => {
  return await api.post(`/threads/${threadId}/messages`, message)
}

export const useSendMessageToThread = (
  onSuccess: (data: any, variables: any) => void,
  onError: (error: any, variables: any) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()

  return useMutation(sendMessageToThread, {
    onSuccess,
    onError,
    onSettled: (data, error, variables) => {
      const { threadId, threadFilter, isClipCreation } = variables

      queryClient.invalidateQueries([QueryKeys.THREAD, threadId, activeOrgId])
      queryClient.invalidateQueries([QueryKeys.MESSAGES, threadId, activeOrgId])
      if (isClipCreation) {
        queryClient.invalidateQueries([
          QueryKeys.THREAD_MEDIA,
          threadId,
          GalleryTab.clips,
          activeOrgId,
        ])
      }

      if (threadFilter) {
        queryClient.invalidateQueries([
          QueryKeys.THREADS,
          threadFilter,
          activeOrgId,
        ])
      }
    },
  })
}

//------------Mark As Read
type MarkAsReadProps = {
  threadId: string
  effectiveUserId: string
  threadFilter?: string
}
const markAsRead = async ({ threadId, effectiveUserId }: MarkAsReadProps) => {
  const payload = {
    'mark-all-read-payload': {
      'viewed-by': effectiveUserId,
      'page-size': Constants.PAGE_SIZES.MESSAGE_FETCH_BATCH_SIZE,
    },
  }
  return await api.post(`/threads/${threadId}/messages`, payload)
}

export const useMarkAsRead = (onError) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()

  return useMutation(markAsRead, {
    onError: (error, { threadFilter }, context) => {
      if (threadFilter) {
        queryClient.setQueryData(
          [QueryKeys.THREADS, threadFilter, activeOrgId],
          context.previousData
        )
      }

      onError(error)
    },
    onMutate: async ({ threadId, effectiveUserId, threadFilter }) => {
      if (threadFilter) {
        await queryClient.cancelQueries([
          QueryKeys.THREADS,
          threadFilter,
          activeOrgId,
        ])
        queryClient.setQueryData(
          [QueryKeys.THREADS, threadFilter, activeOrgId],
          (oldQueryData) => {
            if (oldQueryData) {
              const updatedPages = oldQueryData?.pages.map((page) => {
                const arThreads = page?.data?.threads
                const arUpdatedThreads = arThreads.map((thread) => {
                  if (thread['thread-id'] === threadId) {
                    const updatedThread = thread
                    updatedThread['read-info'][effectiveUserId] = true
                    return updatedThread
                  } else {
                    return thread
                  }
                })
                return {
                  ...page,
                  data: {
                    ...page.data,
                    threads: arUpdatedThreads,
                  },
                }
              })
              return {
                ...oldQueryData,
                pages: updatedPages,
              }
            } else {
              return oldQueryData
            }
          }
        )
      }
    },
    onSettled: (data, error, variables, context) => {
      const { threadFilter } = variables
      queryClient.invalidateQueries([QueryKeys.THREADS_ALL, activeOrgId])
      if (threadFilter) {
        queryClient.invalidateQueries([
          QueryKeys.THREADS,
          threadFilter,
          activeOrgId,
        ])
      }

      queryClient.invalidateQueries([
        QueryKeys.UNREAD_THREADS_COUNT,
        threadFilter,
        activeOrgId,
      ])
    },
  })
}

//--------------------Delete Message------------
type DeleteMessageProps = {
  threadId: string
  messageId: string
}

const deleteMessage = async ({ threadId, messageId }: DeleteMessageProps) => {
  return await api.delete(`/threads/${threadId}/messages/${messageId}`)
}

export const useDeleteMessage = (
  onSuccess: (data: any, variables: any) => void,
  onError: (data: any, variables: any) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(deleteMessage, {
    onSuccess,
    onError,
    onMutate: async ({ threadId, messageId }) => {
      const queryKey = [QueryKeys.MESSAGES, threadId, activeOrgId]
      const message = MessageUtils.findMessageFromCacheById(
        queryClient,
        queryKey,
        messageId
      )
      if (!!message) {
        const updatedMessage = {
          ...message,
          'app-is-deleting': true,
        }
        await MessageUtils.updateQueryCache(
          queryClient,
          queryKey,
          updatedMessage
        )
      }
    },
    onSettled: (data, error, variables, context) => {
      const queryKey = [QueryKeys.MESSAGES, variables.threadId, activeOrgId]
      queryClient.invalidateQueries(queryKey)
    },
  })
}
