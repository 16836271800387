import ClipRightPanelEdit from '../../components/clipCentral/clipRightPanelSlides/ClipRightPanelEdit'
import ClipRightPanelSlides from '../../components/clipCentral/clipRightPanelSlides/ClipRightPanelSlides'
import ThreadList from '../../components/galleyShareTab/ThreadList'
import { ClipCentralRightPanelTypes } from '../../types/ClipCentral'
import { Clip, GalleryItem, GalleryTab, GalleyType } from '../../types/Files'

type ClipCentralRightPanelProps = {
  activeScreen: ClipCentralRightPanelTypes | null
  clip: Clip
  onClose: () => void
}
const ClipCentralRightPanel = (props: ClipCentralRightPanelProps) => {
  const { activeScreen, clip, onClose } = props

  if (activeScreen === ClipCentralRightPanelTypes.AddSlides) {
    return <ClipRightPanelSlides clipId={clip.clipId} onPressClose={onClose} />
  } else if (activeScreen === ClipCentralRightPanelTypes.EditClip) {
    return <ClipRightPanelEdit clipId={clip.clipId} onPressClose={onClose} />
  } else if (activeScreen === ClipCentralRightPanelTypes.Send) {
    return (
      <ThreadList
        item={
          {
            objectId: clip.clipId,
            recordKey: clip.clipId,
            clipId: clip.clipId,
            tab: GalleryTab.clips,
            galleryType: GalleyType.clips,
            uploadedByUserId: clip.postedBy,
            gtUpdatedTime: clip.gtUpdatedTime,
            gtCreatedTime: clip.gtCreatedTime,
          } as GalleryItem
        }
        onCancel={onClose}
      />
    )
  }
  return null
}
export default ClipCentralRightPanel
