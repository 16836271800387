import React from 'react'
import { View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import {
  Text,
  AppColors,
  ComponentOptions,
  Spacer,
  MessageNote,
} from '@gotradie/gt-components'

const { styles } = StyleSheet.create({
  wrapperStyle: {
    width: '100%',
    '@media (min-width: 768px)': {
      width: '450px',
    },
    flexDirection: 'column',
    paddingHorizontal: ComponentOptions.SPACES.LARGE,
  },
  textWrapperStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  childContainerStyle: {
    paddingTop: ComponentOptions.SPACES.LARGE,
  },
  footerTextContainerStyle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
})

type SignUpScreenWrapperProps = {
  children: React.ReactNode
  title?: string
  subTitle?: string
  titleColor?: string
}

const SignUpScreenWrapper = (props: SignUpScreenWrapperProps) => {
  const { title, subTitle, children, titleColor } = props

  const onPressTermsOfService = () => {}

  const onPressPrivacyPolicy = () => {}

  const onPressCookiePolicy = () => {}

  return (
    <View style={styles.wrapperStyle}>
      {(title || subTitle) && (
        <View style={styles.textWrapperStyle}>
          {title && (
            <Text
              h2
              color={titleColor ? titleColor : AppColors.common.secondery}
            >
              {title}
            </Text>
          )}
          {subTitle && (
            <Text h2 color={AppColors.common.white}>
              {subTitle}
            </Text>
          )}
        </View>
      )}

      <View style={styles.childContainerStyle}>
        {children}
        <Spacer size={ComponentOptions.SPACES.LARGEST} />
        <View style={styles.footerTextContainerStyle}>
          <Text
            body_small
            color={AppColors.common.white}
          >{`By continuing, you’re agreeing to our`}</Text>
          <MessageNote
            arMessages={[
              {
                action: () => onPressTermsOfService(),
                color: AppColors.common.secondery,
                title: 'Terms of service',
              },
              {
                color: AppColors.common.white,
                title: ', ',
              },
              {
                action: () => onPressPrivacyPolicy(),
                color: AppColors.common.secondery,
                title: 'privacy policy',
              },

              {
                color: AppColors.common.white,
                title: ' & ',
              },
              {
                action: () => onPressCookiePolicy(),
                color: AppColors.common.secondery,
                title: 'cookie policy',
              },
            ]}
            paddingHorizontal={0}
            paddingVertical={0}
          />
        </View>
      </View>
    </View>
  )
}

export default SignUpScreenWrapper
