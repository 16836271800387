import React, { useEffect, useState } from 'react'
import { View, Modal, TouchableOpacity } from 'react-native'
import { Thread } from '../../types/Thread'
import {
  GalleryItem,
  GalleryTab,
  GalleyType,
  Media,
  Clip,
} from '../../types/Files'
import { useFetchThreadMedia } from '../../hooks/message/useFetchThread'
import { useFetchUserProfile } from '../../hooks/user/useFetchUser'
import StyleSheet from 'react-native-media-query'
import {
  AppColors,
  ComponentOptions,
  Icon,
  Navigation,
} from '@gotradie/gt-components'
import { Constants, MessageUtils } from '../../common'
import Image from '../media/Image'
import VideoPlayer from '../media/VideoPlayer'
import AudioPlayer from '../media/AudioPlayer'
import ThreadList from '../galleyShareTab/ThreadList'
import { downloadMediaFile } from '../../common/S3Util'
import { useScreenType } from '../../common/DimensionsUtil'

import { useFetchClip } from '../../hooks/clip/useFetchClip'

import { ScreenType } from '../../types/Common'
import ClipPreview from '../ClipPreview'

const { styles } = StyleSheet.create({
  containerStyle: {
    flex: 1,
    width: '100%',
    backgroundColor: AppColors.common.darkDrop,
    flexDirection: 'row',
  },
  leftContainerStyle: {
    flex: 1,
    paddingTop: ComponentOptions.SPACES.MEDIUM,
  },
  rightContainerStyle: {
    width: Constants.SCREEN_LAYOUT_DEFAULT_SIZES.RIGHT_PANEL,
    flexDirection: 'row',
  },
  rightContainerSmallScreenStyle: {
    width: '100%',
    position: 'absolute',
    height: '100%',
  },
  innerContainerStyle: {
    flex: 1,
    width: '100%',
  },
  mediaStyle: {
    flex: 1,
    width: '100%',
  },
  iconContainerStyle: {
    width: 40,
    height: 40,
    borderRadius: 40,
    backgroundColor: AppColors.common.dark,
    position: 'absolute',
    top: '45%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  iconLeftContainerStyle: {
    paddingLeft: ComponentOptions.SPACES.MEDIUM,
  },
  iconRightContainerStyle: {
    right: ComponentOptions.SPACES.MEDIUM,
  },
  footerContainerStyle: {
    paddingHorizontal: '35%',
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
    flexDirection: 'row',
    backgroundColor: AppColors.common.dark,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  galleryMediaWrapperStyle: {
    flex: 1,
    paddingBottom: ComponentOptions.SPACES.MEDIUM,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

type ClipPreviewType = {
  item: GalleryItem
}

const ClipPreviewScreen = (props: ClipPreviewType) => {
  const { item } = props
  const clipItem: Clip = item as unknown as Clip
  const { data: clipObj } = useFetchClip(clipItem.clipId)
  const screenType = useScreenType()

  if (clipObj) {
    const getClipDimensions = (screenTypeVal: ScreenType) => {
      let width: any = 375
      let height: any = 675

      if (screenTypeVal === ScreenType.Desktop) {
        width = 375
        height = '90%'
      } else if (screenTypeVal === ScreenType.Mobile) {
        width = '90%'
        height = '90%'
      }

      return { width, height }
    }

    const { width, height } = getClipDimensions(screenType)
    return <ClipPreview clipObj={clipObj} height={height} width={width} />
  }
  return null
}
const MemorizedClipPreview = React.memo(ClipPreviewScreen)

const GalleryMedia = (item: Media) => {
  if (item.galleryType === GalleyType.photo) {
    return (
      <Image
        size={Constants.IMAGE_SIZES.REPLICA}
        s3Key={item.objectId}
        imageProps={{ style: styles.mediaStyle, resizeMode: 'contain' }}
        imageFetchType={Constants.IMAGE_FETCH_TYPE.PHOTO}
        mediaNotAvailablePlaceholderUri={require('../../images/not-available-preview.png')}
        mediaNotSupportedPlaceholderUri={require('../../images/not-supported-preview.png')}
      />
    )
  } else if (item.galleryType === GalleyType.video) {
    return (
      <VideoPlayer
        s3Key={item.objectId}
        videoProps={{ style: styles.mediaStyle }}
      />
    )
  } else if (item.galleryType === GalleyType.audio) {
    return (
      <AudioPlayer
        autoPlay={true}
        s3Key={item.objectId}
        controllersOnly={false}
        audioURL={item.url}
      />
    )
  } else if (item.galleryType === GalleyType.clips) {
    return <MemorizedClipPreview item={item} />
  }
  return null
}

const PrevButton = (props: { onPress: () => void; isLoading: boolean }) => {
  const { onPress, isLoading } = props
  return (
    <TouchableOpacity
      style={[styles.iconContainerStyle, styles.iconLeftContainerStyle]}
      onPress={onPress}
      disabled={isLoading}
    >
      <Icon name={'ArrowLeft'} fill={AppColors.common.white} />
    </TouchableOpacity>
  )
}
const ForwardButton = (props: { onPress: () => void; isLoading: boolean }) => {
  const { onPress, isLoading } = props
  return (
    <TouchableOpacity
      style={[styles.iconContainerStyle, styles.iconRightContainerStyle]}
      onPress={onPress}
      disabled={isLoading}
    >
      <Icon name={'ArrowRight'} fill={AppColors.common.white} />
    </TouchableOpacity>
  )
}

type GalleryFooterProps = {
  item: GalleryItem
  onPressSend: (item: GalleryItem) => void
}
const GalleryFooter = (props: GalleryFooterProps) => {
  const { item, onPressSend } = props

  const onPressDownload = (item: GalleryItem) => {
    if (item.objectId) {
      const itemMedia = item as Media

      downloadMediaFile(itemMedia)
    }
  }

  const enableDownload =
    item.tab === GalleryTab.media && item.galleryType !== GalleyType.audio
  return (
    <View style={styles.footerContainerStyle}>
      {enableDownload && (
        <Icon
          name={'Download'}
          size={22}
          fill={AppColors.common.white}
          stroke={1}
          onPress={() => onPressDownload(item)}
        />
      )}

      <Icon
        name={'Send'}
        size={22}
        fill={AppColors.common.white}
        stroke={1}
        onPress={() => onPressSend(item)}
      />
    </View>
  )
}

type GalleryPreviewProps = {
  isVisible: boolean
  item?: GalleryItem | null //no need to pass when items there
  thread?: Thread //no need to pass when items there
  source?: GalleryTab | undefined //no need to pass when items there
  onClose: () => void
  items?: GalleryItem[] // not need to pass this when use gallery
}

function GalleryPreview(props: GalleryPreviewProps) {
  const {
    thread,
    isVisible,
    source,
    onClose,
    item: passedItem,
    items: passedItems,
  } = props

  const screenType = useScreenType()
  const [item, setItem] = useState<GalleryItem | null>(passedItem || null)
  const [isOpenedThreadList, setOpenThreadList] = useState<boolean>(false)
  const [currentIndex, setCurrentIndex] = useState(passedItem?.index || 0)

  const { data: uploadedUser } = useFetchUserProfile(
    item?.uploadedByUserId || '',
    item !== null
  )

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useFetchThreadMedia(
      thread?.threadId || '',
      source as string,
      !((passedItems || []).length > 0 || thread?.threadId === undefined),
      false
    )

  useEffect(() => {
    if (passedItem) {
      setItem(passedItem || null)
      setCurrentIndex(passedItem?.index || 0)
    }
  }, [passedItem])

  useEffect(() => {
    if (passedItems) {
      setItem(passedItems[currentIndex])
    } else if (data) {
      const selectedItem = (
        data ? data?.pages.at(currentIndex) : null
      ) as GalleryItem | null
      setItem(selectedItem)
    }
  }, [currentIndex])

  function onPressPrev() {
    const items: GalleryItem[] =
      passedItems || (data?.pages as unknown as GalleryItem[]) || []

    if (items.length > 0 && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  function onPressForward() {
    const items: GalleryItem[] =
      passedItems || (data?.pages as unknown as GalleryItem[]) || []

    if (currentIndex < items.length - 1) {
      setCurrentIndex(currentIndex + 1)
    } else if (data?.pages && hasNextPage) {
      fetchNextPage()
    }
  }

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={() => {}}
    >
      <View style={styles.containerStyle}>
        <View style={styles.leftContainerStyle}>
          <Navigation
            title={`${uploadedUser?.firstName || ''} ${
              uploadedUser?.lastName || ''
            }`}
            subTitle={
              item?.gtUpdatedTime
                ? `${MessageUtils.createThreadLastUpdatedTime(
                    item?.gtUpdatedTime || '',
                    true
                  )}`
                : undefined
            }
            leftIcon={
              <Icon
                name="Remove"
                fill={AppColors.common.white}
                size={25}
                onPress={onClose}
              />
            }
          />

          <View style={styles.innerContainerStyle}>
            <PrevButton
              onPress={() => onPressPrev()}
              isLoading={isFetchingNextPage}
            />
            <View style={styles.galleryMediaWrapperStyle}>
              {item &&
                (item.tab === GalleryTab.media ||
                  item.tab === GalleryTab.clips) && (
                  <GalleryMedia {...(item as Media)} />
                )}
            </View>

            <ForwardButton
              onPress={() => onPressForward()}
              isLoading={isFetchingNextPage}
            />
            {item && (
              <GalleryFooter
                item={item}
                onPressSend={() => setOpenThreadList(true)}
              />
            )}
          </View>
        </View>
        {isOpenedThreadList && (
          <View
            style={
              screenType === ScreenType.Desktop
                ? styles.rightContainerStyle
                : styles.rightContainerSmallScreenStyle
            }
          >
            <ThreadList
              item={item as GalleryItem}
              onCancel={() => setOpenThreadList(false)}
            />
          </View>
        )}
      </View>
    </Modal>
  )
}

export default GalleryPreview
