import { Auth } from 'aws-amplify'

const awsConfig = {
  Auth: {
    // Amazon Cognito Region
    region: process.env.REGION,

    // Amazon Cognito User Pool ID
    userPoolId: process.env.COGNITO_POOL_ID,

    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'api',
        endpoint: process.env.DOMAIN_API_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          }
        },
      },
    ],
  },
}

export default {
  ...awsConfig,
}
