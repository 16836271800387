import React, { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query'
import NavigationStack from './navigation/NavigationStack'
import { ThemeProvider, themes, FlashMessage } from '@gotradie/gt-components'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  useActiveOrgId,
  useActiveUserId,
  UserContextProvider,
  useUserContext,
} from './context/UserContext'
import ContentWrapper from './layout/ContentWrapper'
import { AuthUtil, Constants, MessageUtils, QueryKeys } from './common'
import ClientWebSocket from './common/ClientWebSocket'
import NotificationUtil from './common/NotificationUtil'
import { defaultEventParameters } from './analytics/index'
import { setDefaultEventParameters } from 'firebase/analytics'

setDefaultEventParameters(defaultEventParameters)

function Content() {
  const activeUserId = useActiveUserId()
  const activeOrgId = useActiveOrgId()
  const queryClient = useQueryClient()
  const { activeTheme, isAuthenticated, setAuthenticated } = useUserContext()

  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    // setLoading(true)
    AuthUtil.getCurrentSession()
      .then((session) => {
        setAuthenticated(session && session.isValid())
      })
      .catch(() => {
        setAuthenticated(false)
      })
      .finally(() => {
        setLoading(false)
      })

    window.addEventListener('focus', onWindowFocused)
    document.addEventListener('visibilitychange', onBrowserTabFocused)
    return () => {
      window.removeEventListener('focus', onWindowFocused)
      !!ws && ws.closeWebSocket()
    }
  }, [])

  let ws: ClientWebSocket
  useEffect(() => {
    if (!activeUserId || !activeOrgId) {
      return
    }
    invalidateIndicators()
    if (isAuthenticated) {
      !!!ws && initWebSocket()
    } else {
      !!ws && ws.closeWebSocket()
    }
  }, [isAuthenticated, activeUserId, activeOrgId])

  function onWindowFocused() {
    invalidateIndicators()
    !!ws && ws.reconnectIfDisconnected() //Web Socket disconnects if the window is out of focus for more than 5 min
  }

  function onBrowserTabFocused() {
    if (document.visibilityState === 'visible') {
      document.title = `${Constants.TITLE}`
    }
  }

  function initWebSocket() {
    ws = ClientWebSocket.getWebSocket(activeUserId, activeOrgId)
    ws.initiateHeartBeat()
    ws.subscribe(onNotification)
  }

  function invalidateIndicators() {
    MessageUtils.invalidateNotificationIndicatorQueries(
      queryClient,
      activeOrgId
    )
  }
  function updateTitle(received: any) {
    if (document.visibilityState === 'hidden') {
      if (received.status) {
        return
      }
      document.title = `(${getNotificationNumber() + 1}) ${Constants.TITLE}`
    }
  }

  function getNotificationNumber() {
    const re = new RegExp(/^\((\d+)\).*$/)
    const matches = document.title.match(re)
    if (matches) {
      return parseInt(matches[1])
    } else {
      return 0
    }
  }

  function onNotification(received: any) {
    updateTitle(received)
    if (received.action === Constants.WEB_SOCKET_MESSAGE_ACTIONS.NOTIFICATION) {
      //handle forground notifications
      console.log('notification payload received', received)

      //TODO show notification
      queryClient.invalidateQueries([QueryKeys.NOTIFICATION_LIST, activeOrgId])
      //invalidate home page widgets
      // queryClient.invalidateQueries([QueryKeys.WIDGETS, activeOrgId])

      // refetch notifications
      queryClient.invalidateQueries([QueryKeys.NOTIFICATION_LIST, activeOrgId])

      //TODO check the dto mapping
      const scenario = NotificationUtil.findNotificationScenario(received)
      if (
        scenario ===
        Constants.NOTIFICATION_SCENARIO.OWNER_ACCEPTED_REQUEST_NOTIFY_WORKER
      ) {
        queryClient.invalidateQueries(QueryKeys.MY_PROFILE)
        queryClient.invalidateQueries(QueryKeys.USER_ORG_ROLES)
      } else if (
        scenario ===
        Constants.NOTIFICATION_SCENARIO.OWNER_REJECTED_REQUEST_NOTIFY_WORKER
      ) {
        queryClient.invalidateQueries(QueryKeys.USER_ORG_ROLES)
      }
    }
  }

  return (
    <ThemeProvider theme={themes[activeTheme]}>
      <ContentWrapper isLoading={isLoading} fillScreen={true}>
        <NavigationStack />
        <FlashMessage
          floating={true}
          duration={30000}
          testID={'flashMessage'}
        />
      </ContentWrapper>
    </ThemeProvider>
  )
}

export const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <Content />
        <ReactQueryDevtools initialIsOpen={false} />
      </UserContextProvider>
    </QueryClientProvider>
  )
}
