export default {
  HOME: 'HOME',
  LOGIN: 'LOGIN',
  CHAT: 'CHAT',
  FIND: 'FIND',
  SETTINGS: 'SETTINGS',
  PROFILE: 'PROFILE',
  BUSINESS_PROFILE: 'BUSINESS PROFILE',
  NOTIFICATIONS: 'NOTIFICATIONS',
  SIGNUP: 'SIGNUP',
  ADD_NEW_ACCOUNT: 'ADD NEW ACCOUNT',
  CLAIM_BUSINESS: 'CLAIM BUSINESS',
  CLIPS_CENTRAL: 'CLIPS CENTRAL',
  CLIP: 'CLIP',
  CONTACTS: 'CONTACTS',
  SUCCESSWAIT: 'SUCCESSWAIT',
  REDIRECT: 'REDIRECT',

  //use this for page layout testing
  TEST: 'TEST PAGE',
}
