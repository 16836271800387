import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent as logFirebaseEvent } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
analytics.app.automaticDataCollectionEnabled = true

export const defaultEventParameters = {
  user_source: 'web',
}

//(analyticsInstance, eventName, eventParams, options)
export function logEvent(eventName: string, eventParams?: object) {
  logFirebaseEvent(analytics, eventName, {
    ...eventParams,
    ...defaultEventParameters,
  })
}
