import React, { createContext, useContext, useEffect } from 'react'
import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import type {
  TabPanelProps,
  TabSwitcherProps,
  TabContextProps,
  TabHeaderProps,
  TabProps,
} from '../../types/Tab'

import {
  AppColors,
  Icon,
  Text,
  ComponentOptions,
} from '@gotradie/gt-components'
import StyleSheet from 'react-native-media-query'

const { styles } = StyleSheet.create({
  tabHeaderContainerStyle: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  tabContainerStyle: {
    flex: 1,
    alignItems: 'center',
    borderBottomColor: AppColors.common.concrete,
    borderBottomWidth: 1,
  },
  tabInnerStyle: {
    borderBottomColor: AppColors.common.transparent,
    borderBottomWidth: 2,
    marginBottom: -1,
  },
  tabInnerActiveStyle: {
    borderBottomColor: AppColors.common.primary,
  },
  tabItemStyle: {
    position: 'relative',
    flexDirection: 'row',
    flex: 1,
    paddingBottom: ComponentOptions.SPACES.SMALL,
  },
  tabLoadingStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badge: {
    position: 'absolute',
    top: -1,
    left: -1,
    backgroundColor: AppColors.common.primary,
    width: ComponentOptions.SPACES.SMALL,
    height: ComponentOptions.SPACES.SMALL,
    borderRadius: 4,
  },
})

const DataContext = createContext<TabContextProps | null>(null)

function Tab(props: TabProps) {
  const {
    icon,
    title,
    titleProps,
    tabKey,
    activeTextColor,
    inactiveTextColor,
    indicator,
  } = props
  const { activeTab, onChangeActiveTab } = useContext(
    DataContext
  ) as TabContextProps

  const tabInnerStyle = [
    styles.tabInnerStyle,
    activeTab === tabKey ? styles.tabInnerActiveStyle : {},
  ]
  const textColor =
    activeTab === tabKey
      ? activeTextColor || AppColors.common.subtitleText
      : inactiveTextColor || AppColors.common.concrete
  return (
    <TouchableOpacity
      style={styles.tabContainerStyle}
      onPress={() => onChangeActiveTab(tabKey)}
    >
      <View style={tabInnerStyle}>
        <View style={styles.tabItemStyle}>
          {icon && <Icon {...icon} fill={textColor} />}
          <Text
            color={textColor}
            StyleProps={{
              paddingLeft: icon ? ComponentOptions.SPACES.SMALL : 0,
            }}
            {...titleProps}
          >
            {title}
          </Text>
          {indicator && <View style={styles.badge} />}
        </View>
      </View>
    </TouchableOpacity>
  )
}

function TabHeader({ children }: TabHeaderProps) {
  return <View style={styles.tabHeaderContainerStyle}>{children}</View>
}

function TabPanel({ displayKey, children }: TabPanelProps) {
  const { activeTab } = useContext(DataContext) as TabContextProps
  return (
    <React.Fragment>
      {activeTab === displayKey ? children : null}
    </React.Fragment>
  )
}

function TabSwitcher(props: TabSwitcherProps) {
  const { activeTab, children, isLoading, onChangeActiveTab } = props

  useEffect
  return (
    <DataContext.Provider value={{ activeTab, onChangeActiveTab }}>
      {isLoading ? (
        <View style={styles.tabLoadingStyle}>
          <ActivityIndicator color={AppColors.common.primary} size={'large'} />
        </View>
      ) : (
        children
      )}
    </DataContext.Provider>
  )
}

export default TabSwitcher
export { Tab, TabPanel, TabHeader }
