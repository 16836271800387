import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import {
  AppColors,
  ListItem,
  ComponentOptions,
  Text,
  MessageNote,
  Shimmer,
  themes,
} from '@gotradie/gt-components'
import { Constants, MessageUtils, Util } from '../../common'
import { Profile } from '../../types/User'
import { Thread, ThreadShareItem, ShareStatus } from '../../types/Thread'

import AvatarGroup from '../AvatarGroup'
import StyleSheet from 'react-native-media-query'

/* Styles */
const { styles } = StyleSheet.create({
  chatListItemContainerStyle: {
    borderWidth: 0,
    shadowColor: AppColors.common.transparent,
    elevation: 0,
    paddingVertical: ComponentOptions.SPACES.SMALL,
    borderRadius: 0,
    flex: 1,
    backgroundColor: AppColors.common.white,
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
  },
  summaryMessageContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  chatListItemRightElementStyle: {
    flex: 1,
    alignItems: 'flex-start',
    paddingTop: ComponentOptions.SPACES.SMALL,
  },
  unReadMsgDotStyle: {
    alignSelf: 'flex-end',
    marginTop: ComponentOptions.SPACES.SMALL,
    height: ComponentOptions.SPACES.SMALL,
    width: ComponentOptions.SPACES.SMALL,
    borderRadius: ComponentOptions.SPACES.EXTRA_SMALL,
    backgroundColor: AppColors.common.primary,
  },
  shareRightIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  chatThreadSkeletonStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    margin: ComponentOptions.SPACES.MEDIUM,
  },
  chatThreadSkeletonLeftStyle: { marginRight: ComponentOptions.SPACES.MEDIUM },
  chatThreadSkeletonMidStyle: { marginBottom: ComponentOptions.SPACES.SMALL },
})

type ThreadAvatarProps = {
  thread: Thread
  currentUserId: string
}

function ThreadAvatar(props: ThreadAvatarProps) {
  const { thread, currentUserId } = props
  const profilePicArray = MessageUtils.createThreadProfilePicArray(
    thread,
    currentUserId
  )
  return (
    <AvatarGroup
      avatarProps={profilePicArray}
      imageFetchType={Constants.IMAGE_FETCH_TYPE.PROFILE}
      avatarGroupProps={{
        groupChat: true,
        uniqueId: thread.threadId,
        size:
          profilePicArray.length > 1
            ? ComponentOptions.AVATAR_SIZE.EXTRA_EXTRA_SMALL
            : ComponentOptions.AVATAR_SIZE.SMALL,
      }}
    />
  )
}

const MemorizedThreadAvatar = React.memo(ThreadAvatar)

const ShareChatListRight = (props: {
  isLoading: boolean
  onPressSend: (thread: ThreadShareItem) => void
  threadShareItem: ThreadShareItem
}) => {
  const { onPressSend, isLoading, threadShareItem } = props

  if (threadShareItem.shareStatus === ShareStatus.sent) {
    return (
      <MessageNote
        color={AppColors.common.primary}
        arMessages={[
          {
            title: 'Done',
            paddingRight: ComponentOptions.SPACES.EXTRA_SMALL,
          },
          {
            icon: {
              name: 'Valid',
              fill: AppColors.common.primary,
              size: 20,
            },
          },
        ]}
      />
    )
  } else if (threadShareItem.shareStatus === ShareStatus.sending) {
    return (
      <View style={styles.shareRightIconContainer}>
        <Text body_small color={AppColors.common.subtitleText}>
          {'Sending '}
        </Text>
        <ActivityIndicator size={'small'} color={AppColors.common.primary} />
      </View>
    )
  }
  return (
    <MessageNote
      onPress={() => (isLoading ? undefined : onPressSend(threadShareItem))}
      color={AppColors.common.subtitleText}
      arMessages={[
        {
          title: 'Send',
          paddingRight: ComponentOptions.SPACES.EXTRA_SMALL,
        },
        {
          icon: {
            name: 'DetailedSend',
            size: 20,
            fill: AppColors.common.subtitleText,
          },
        },
      ]}
    />
  )
}

const ThreadListSubItem = (item: Thread) => {
  const subTitle = item.participants
    ? `${item.participants.length} Members`
    : ''
  return (
    <Text body_small color={AppColors.common.subtitleText}>
      {subTitle}
    </Text>
  )
}

const SkeletonView = () => {
  const { width } = Util.getWindowDimensions()
  const ShimmerWidth = width < Constants.BREAK_POINT ? width * 0.8 : 270
  return (
    <View style={styles.chatThreadSkeletonStyle}>
      <View style={styles.chatThreadSkeletonLeftStyle}>
        <Shimmer
          width={50}
          height={50}
          borderRadius={50}
          theme={themes.light}
        />
      </View>
      <View>
        <View style={styles.chatThreadSkeletonMidStyle}>
          <Shimmer
            width={ShimmerWidth}
            height={15}
            borderRadius={15}
            theme={themes.light}
          />
        </View>
        <Shimmer
          width={ShimmerWidth * 0.6}
          height={15}
          borderRadius={15}
          theme={themes.light}
        />
      </View>
    </View>
  )
}

type ThreadListItem = {
  threadShareItem?: ThreadShareItem
  currentUser: Profile | undefined
  onPress: (thread: ThreadShareItem) => void
  isLoading: boolean
}
function ThreadListItem(props: ThreadListItem) {
  const { threadShareItem, currentUser, onPress, isLoading } = props
  if (threadShareItem && currentUser) {
    const title = MessageUtils.getThreadTitle(
      threadShareItem,
      currentUser?.userId || ''
    )

    return (
      <ListItem
        title={title}
        titleProps={{
          body_strong: true,
          color: AppColors.common.tertiary,
          numberOfLines: 1,
          ellipsizeMode: 'tail',
        }}
        containerStyle={styles.chatListItemContainerStyle}
        marginBottom={0}
        leftElement={
          <MemorizedThreadAvatar
            thread={threadShareItem}
            currentUserId={currentUser?.userId || ''}
          />
        }
        rightElement={
          <ShareChatListRight
            threadShareItem={threadShareItem}
            onPressSend={onPress}
            isLoading={isLoading}
          />
        }
        subTitle={<ThreadListSubItem {...threadShareItem} />}
      />
    )
  }
  return <SkeletonView />
}

const MemorizedThreadListItem = React.memo(ThreadListItem)
export default MemorizedThreadListItem
