import {
  GetObjectCommand,
  PutObjectCommand,
  S3Client,
} from '@aws-sdk/client-s3'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import AuthUtil from './AuthUtil'
import { Media } from '../types/Files'
import Util from './Util'
import S3UploadHttpHandler from './S3UploadHttpHandler'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'

const getS3Client = async (requestHandler?: any) => {
  const session = await AuthUtil.getCurrentSession()
  const token = session.getIdToken().getJwtToken()
  const COGNITO_ID = `cognito-idp.${process.env.REGION}.amazonaws.com/${process.env.COGNITO_POOL_ID}`

  return new S3Client({
    region: process.env.REGION,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: process.env.REGION }),
      identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID || '',
      logins: {
        [COGNITO_ID]: token,
      },
    }),
    requestHandler: requestHandler,
  })
}

async function getPreSignedUrlFromS3(
  bucketName: string,
  key: string,
  responseType = '',
  fileName = ''
) {
  const s3Client = await getS3Client()
  const command = new GetObjectCommand({
    Bucket: bucketName,
    Key: key,
    ResponseContentType: responseType,
    ResponseContentDisposition: fileName
      ? `attachment; filename="${fileName}"`
      : undefined,
  })

  return await getSignedUrl(s3Client, command, { expiresIn: 3600 })
}

export async function fetchMediaS3Key(
  key: string,
  responseType = '',
  fileName = ''
) {
  return await getPreSignedUrlFromS3(
    process.env.MEDIA_S3_BUCKET || '',
    key,
    responseType,
    fileName
  )
}

export async function fetchDocumentS3Key(key: string) {
  return await getPreSignedUrlFromS3(process.env.DOCUMENTS_S3_BUCKET || '', key)
}

export async function downloadMediaFile(item: Media) {
  const extension = item.extension
    ? item.extension
    : Util.getDefaultExtension(item.galleryType, item)
  const fileName = `${item.objectId?.replace('/', '_')}.${extension}`
  const key = item.objectId || ''
  const url = await fetchMediaS3Key(key, '', fileName)

  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = fileName
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}

export function dataURItoFile(filename: string, dataURI: string) {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeString })
  const file = new File([blob], filename, { type: mimeString })
  return file
}

export async function uploadObject(
  key: string,
  file: File,
  metadata?: any,
  bucket?: string,
  callback?: (key: string, percentComplete: number) => void
) {
  const buffer = await file.arrayBuffer()

  const uploadParams = {
    Bucket: bucket,
    Key: key,
    Body: buffer,
    ContentType: file.type,
    Metadata: {
      client: 'web',
      ...metadata,
    },
  }
  const uploadHttpHandler = new S3UploadHttpHandler()

  uploadHttpHandler.onProgress$.subscribe((progress) => {
    const percentComplete =
      (progress.progressEvent.loaded / progress.progressEvent.total) * 100
    callback?.(key, percentComplete)
  })

  const s3Client: S3Client = await getS3Client(uploadHttpHandler)

  try {
    const putCommand = new PutObjectCommand(uploadParams)
    const result = await s3Client.send(putCommand)
    if (result.$metadata.httpStatusCode === 200) {
      return true
    }
  } catch (error) {
    return false
  }
}
