import { ReactElement, useEffect, useState } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { fetchMediaS3Key } from '../../../common/S3Util'
import {
  ComponentOptions,
  AppColors,
  Icon,
  Spacer,
  Text,
} from '@gotradie/gt-components'
import MediaController from '../MediaController'
import useAudioPlayer from './useAudioPlayer'

const { styles } = StyleSheet.create({
  audioPlayerWrapperStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    width: '100%',
  },
  audioPlayerContainer: {
    width: '100%',
    flex: 1,
    backgroundColor: AppColors.common.dark,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

type AudioPlayerProps = {
  s3Key?: string
  controllersOnly: boolean
  autoPlay?: boolean
  audioURL?: string
  containerStyle?: StyleProp<ViewStyle>
}

const AudioPlayerWrapper = (props: {
  children: ReactElement
  isWrapped: boolean
}) => {
  if (props.isWrapped) {
    return (
      <View style={styles.audioPlayerWrapperStyle}>
        <View style={styles.audioPlayerContainer}>
          <Icon
            name={'AudioFile'}
            size={80}
            stroke={1}
            fill={AppColors.common.primary}
          />
          <Spacer size={ComponentOptions.SPACES.SMALL} />
          <Text
            body_small
            color={AppColors.common.primary}
          >{`Audio clip`}</Text>
        </View>
        {props.children}
      </View>
    )
  }
  return props.children
}

const AudioPlayer = (props: AudioPlayerProps) => {
  const { audioURL, s3Key, controllersOnly, containerStyle } = props

  const [audioUri, setAudioUri] = useState<string | undefined>(undefined)
  const {
    curTime,
    duration,
    playing,
    onPlay,
    onPause,
    isCompleted,
    onSeeking,
    onUnmounted,
  } = useAudioPlayer(audioUri)

  useEffect(() => {
    if (s3Key && s3Key.length > 0) {
      fetchMediaS3Key(s3Key)
        .then((uri: string) => {
          setAudioUri(uri)
        })
        .catch(() => {
          setAudioUri(undefined)
        })
    }
  }, [s3Key])

  useEffect(() => {
    return () => {
      onUnmounted()
    }
  }, [])

  useEffect(() => {
    if (!!audioURL) {
      setAudioUri(audioURL)
    }
  }, [audioURL])

  return (
    <AudioPlayerWrapper isWrapped={!controllersOnly}>
      <MediaController
        onPlay={() => onPlay()}
        onPause={() => onPause()}
        isPlaying={playing}
        currentSeek={curTime}
        maxSeek={duration}
        handleSeekChange={(value: number) => () => onSeeking(value)}
        isCompleted={isCompleted}
        disabled={!audioUri}
        containerStyles={containerStyle}
      />
    </AudioPlayerWrapper>
  )
}

export default AudioPlayer
