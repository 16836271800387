import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import NavigationKeys from '../navigation/NavigationKeys'
import { useNavigate, useLocation } from 'react-router-dom'

import {
  AppColors,
  Icon,
  ComponentOptions,
  Text,
} from '@gotradie/gt-components'
import NavigationName from '../navigation/NavigationName'

const { styles } = StyleSheet.create({
  headerStyle: {
    width: '100%',
    height: '50px',
    backgroundColor: AppColors.common.tertiary,
    borderColor: AppColors.common.whiteDrop,
    borderBottomWidth: 1,
    display: 'none',
    '@media (max-width: 1024px)': {
      display: 'flex',
    },
  },
  hamburgerIconWrapper: {
    width: '50px',
    height: '50px',
    justifyContent: 'center',
  },
  modalWrapper: {
    display: 'flex',
    position: 'absolute',
    top: '50px',
    zIndex: 9999,
    backgroundColor: AppColors.common.tertiary,
    borderColor: AppColors.common.whiteDrop,
    borderBottomWidth: 1,
    width: '100%',
    minHeight: '100px',
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
  },
  modalContentWrapper: {},
  menuItemWrapper: {
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
    paddingHorizontal: ComponentOptions.SPACES.LARGE,
  },
})

const MobileMenu = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [isMenuVisible, setMenuVisiblility] = useState(false)

  function toggleMenu() {
    setMenuVisiblility(false)
  }

  function getButtonColor(path: string) {
    if (location.pathname == path) {
      return AppColors.common.concrete
    } else {
      return AppColors.common.subtitleText
    }
  }

  // TODO implement Profile & notification functions - GT-2444
  return (
    <>
      <View style={styles.headerStyle}>
        <Icon
          name={isMenuVisible ? 'Close' : 'Hamburger'}
          wrapperStyles={styles.hamburgerIconWrapper}
          fill={AppColors.common.concrete}
          size={20}
          onPress={() => setMenuVisiblility(!isMenuVisible)}
        />
      </View>

      {isMenuVisible && (
        <View style={styles.modalWrapper}>
          <TouchableOpacity
            style={styles.menuItemWrapper}
            onPress={() => navigate(NavigationKeys.HOME)}
          >
            <Text button color={getButtonColor(NavigationKeys.HOME)}>
              {NavigationName.HOME}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.menuItemWrapper}
            onPress={() => navigate(NavigationKeys.CHAT)}
          >
            <Text button color={getButtonColor(NavigationKeys.CHAT)}>
              {NavigationName.CHAT}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.menuItemWrapper}
            onPress={() => navigate(NavigationKeys.FIND)}
          >
            <Text button color={getButtonColor(NavigationKeys.FIND)}>
              {NavigationName.FIND}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.menuItemWrapper}
            onPress={() => navigate(NavigationKeys.SETTINGS)}
          >
            <Text button color={getButtonColor(NavigationKeys.SETTINGS)}>
              {NavigationName.SETTINGS}
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </>
  )
}

export default MobileMenu
