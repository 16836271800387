import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Constants } from '../common'
import ForgotPasswordStep1 from '../containers/forgotPassword/ForgotPasswordStep1'
import ForgotPasswordStep2 from '../containers/forgotPassword/ForgotPasswordStep2'
import ForgotPasswordStep3 from '../containers/forgotPassword/ForgotPasswordStep3'
import { useUserContext } from '../context/UserContext'
import SignUpLayout from '../layout/SignUpLayout'
import Nav from '../navigation/NavigationKeys'
import { ForgotPasswordResultProps } from '../types/ForgotPassword'
import {
  ForgotPasswordIncompleteScreens,
  SignUpIncompleteScreens,
} from '../types/SignUp'

enum ForgotPasswordStep {
  STEP1,
  STEP2,
  STEP3,
}

const ForgotPasswordPage = () => {
  const navigate = useNavigate()
  const { username, screen } = useParams()
  const [step, setStep] = useState(ForgotPasswordStep.STEP1)
  const [result, setResult] = useState<ForgotPasswordResultProps | null>(null)
  const { setAuthenticated } = useUserContext()

  useEffect(() => {
    if (username && screen) {
      validateParams(username, screen as ForgotPasswordIncompleteScreens)
    } else {
      setStep(ForgotPasswordStep.STEP1)
    }
  }, [username, screen])

  function validateParams(
    username: string,
    screen: ForgotPasswordIncompleteScreens
  ) {
    setResult({ mobileNumber: username })
    if (screen == ForgotPasswordIncompleteScreens.setPassword) {
      setStep(ForgotPasswordStep.STEP3)
    } else {
      onBackForgotPassword()
    }
  }

  const onBackLogin = () => {
    navigate(Nav.LOGIN)
  }

  const onBackForgotPassword = () => {
    navigate(Nav.RESET_PASSWORD)
  }

  const onCompletionStep1 = (props: ForgotPasswordResultProps) => {
    setResult(props)
    setStep(ForgotPasswordStep.STEP2)
  }
  const onCompletionStep2 = (props: ForgotPasswordResultProps) => {
    setResult(props)
    setStep(ForgotPasswordStep.STEP3)
  }
  const onCompletionStep3 = () => {
    setAuthenticated(false)
    setTimeout(() => onBackLogin(), Constants.TIMEOUT.DEFAULT)
  }

  const onUserNotConfirmed = (props: ForgotPasswordResultProps) => {
    navigate(
      `${Nav.SIGNUP}/${props.mobileNumber}/${SignUpIncompleteScreens.verifyAccount}`
    )
  }

  const onPasswordNotSet = (props: ForgotPasswordResultProps) => {
    navigate(
      `${Nav.SIGNUP}/${props.mobileNumber}/${SignUpIncompleteScreens.setPassword}`
    )
  }

  return (
    <SignUpLayout>
      {step === ForgotPasswordStep.STEP1 && (
        <ForgotPasswordStep1
          onCompletionCallback={onCompletionStep1}
          onBackPress={onBackLogin}
          onUserNotConfirmed={onUserNotConfirmed}
          onPasswordNotSet={onPasswordNotSet}
        />
      )}
      {step === ForgotPasswordStep.STEP2 && result && (
        <ForgotPasswordStep2
          onCompletionCallback={onCompletionStep2}
          onBackPress={onBackLogin}
          data={result}
        />
      )}
      {step === ForgotPasswordStep.STEP3 && result && (
        <ForgotPasswordStep3
          onCompletionCallback={onCompletionStep3}
          onBackPress={onBackLogin}
          data={result}
        />
      )}
    </SignUpLayout>
  )
}

export default ForgotPasswordPage
