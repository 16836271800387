import { useEffect } from 'react'
import { View, ActivityIndicator } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { Text, Spacer, AppColors } from '@gotradie/gt-components'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetchInvitation } from '../hooks/invitation/useFetchInvitation'
import { MessageUtils, Util } from '../common'
import { InvitationType, MagicLink } from '../types/Invitation'
import NavigationKeys from '../navigation/NavigationKeys'
import { useFetchMyProfile } from '../hooks/user/useFetchUser'
import { useInviteMagicLink } from '../hooks/invitation/useMutateInvitation'
import { ServerError } from '../types/Common'
import { useActiveRoleType } from '../context/UserContext'
import { RoleType } from '../types/User'

const { styles } = StyleSheet.create({
  centerContainer: {
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
})

const MagicLinkAuthenticated = () => {
  const navigate = useNavigate()
  const params = useParams()
  const InvitationId = params?.id

  const activeRoleType = useActiveRoleType()
  const { data: profile } = useFetchMyProfile(true)

  const canShowThread =
    activeRoleType === RoleType.tradieOwner ||
    activeRoleType === RoleType.tradieAdmin ||
    activeRoleType === RoleType.tradieWorker

  const { data } = useFetchInvitation(
    { id: InvitationId || '', invitationType: InvitationType.MAGIC_LINK },
    true,
    (er) => {
      Util.showErrorMessage(er.message || '')
      navigate(NavigationKeys.HOME)
    }
  )

  const { mutate: onSendInvite } = useInviteMagicLink(onSuccessInvite, onError)

  useEffect(() => {
    if (!!data && !!profile) {
      if (canShowThread) {
        onSendInvite({
          phoneNumber: profile?.phoneNumber,
          magicToken: InvitationId as string,
        })
      } else {
        redirectToMagicLinkPendingInvite()
      }
    }
  }, [data, profile])

  function onSuccessInvite() {
    const magicLinkData = data as MagicLink
    navigate(
      `${NavigationKeys.CHAT}/${MessageUtils.getThreadFilterFromType(
        magicLinkData.threadInfo?.threadType || ''
      )}/${magicLinkData.threadId}`
    )
  }
  function onError(er: ServerError) {
    Util.showErrorMessage(er.message || '')
    navigate(NavigationKeys.HOME)
  }

  function redirectToMagicLinkPendingInvite() {
    navigate(`${NavigationKeys.MAGIC_LINK_PENDING_WORKER}/${InvitationId}`)
  }

  return (
    <View style={styles.centerContainer}>
      <Text body color={AppColors.common.white}>
        {canShowThread ? `Redirecting to the thread..` : 'Redirecting...'}
      </Text>
      <Spacer />
      <ActivityIndicator color={AppColors.common.white} />
    </View>
  )
}

export default MagicLinkAuthenticated
