import {
  SignupTemplate,
  SuccessMagicLinkPendingWorker,
} from '@gotradie/gt-components'
import { useNavigate } from 'react-router-dom'
import NavigationKeys from '../navigation/NavigationKeys'

const MagicLinkPendingWorkerInvite = () => {
  const navigate = useNavigate()

  function onPressGoHome() {
    navigate(NavigationKeys.HOME, { replace: true, state: null })
  }

  return (
    <SignupTemplate>
      <SuccessMagicLinkPendingWorker onPressButton={() => onPressGoHome()} />
    </SignupTemplate>
  )
}

export default MagicLinkPendingWorkerInvite
