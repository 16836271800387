import React from 'react'
import { Pressable, View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { AppColors, ComponentOptions, Icon } from '@gotradie/gt-components'
import NavigationKeys from '../navigation/NavigationKeys'
import { useNavigate, useLocation } from 'react-router-dom'
import { useFetchMyProfile } from '../hooks/user/useFetchUser'
import { Constants, Util } from '../common'
import Avatar from '../components/Avatar'
import { useFetchBusiness } from '../hooks/business/useFetchBusiness'
import { useActiveOrgId } from '../context/UserContext'
import { useFetchNotifications } from '../hooks/notification/useFetchNotification'
import NavigationName from '../navigation/NavigationName'
import { useFetchUnreadThreadsCount } from '../hooks/message/useFetchThread'

const { styles } = StyleSheet.create({
  shadowStyle: {
    borderColor: AppColors.common.whiteDrop,
    borderEndWidth: '1px',
  },
  wrapper: {
    width: '50px',
    height: '100vh',
    backgroundColor: AppColors.common.tertiary,
    justifyContent: 'space-between',
    display: 'flex',
    '@media (max-width: 1024px)': {
      display: 'none',
    },
  },
  iconWrapper: {
    width: '50px',
    height: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: ComponentOptions.SPACES.SMALL,
    marginBottom: ComponentOptions.SPACES.SMALL,
  },
  badge: {
    position: 'absolute',
    top: 20,
    right: 10,
    backgroundColor: AppColors.common.red,
    width: ComponentOptions.SPACES.SMALL,
    height: ComponentOptions.SPACES.SMALL,
    borderRadius: 4,
  },
})

const Menu = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { data } = useFetchMyProfile()

  const activeOrgId = useActiveOrgId()

  const { data: business } = useFetchBusiness(activeOrgId)

  const { data: unreadThreadsCount } = useFetchUnreadThreadsCount()
  const { data: notifications } = useFetchNotifications()

  function toggleMenu() {}

  function getIconColor(path: string) {
    if (location.pathname == path) {
      return AppColors.common.concrete
    } else {
      return AppColors.common.subtitleText
    }
  }

  function renderUnreadNotificationCount(menu: string) {
    let badgeCount = 0
    if (menu === NavigationName.HOME || menu === NavigationName.NOTIFICATIONS) {
      badgeCount = ((notifications || []) as any[]).length
    } else if (menu === NavigationName.CHAT) {
      if (!!unreadThreadsCount && Object.keys(unreadThreadsCount).length > 0) {
        const count = Object.values(unreadThreadsCount).reduce(
          (sum, c) => parseInt(String(sum)) + parseInt(String(c))
        )
        badgeCount = !!count ? parseInt(String(count)) : 0
      }
    }
    if (badgeCount > 0) {
      return <View style={styles.badge} />
    } else {
      return null
    }
  }
  // TODO implement Profile & notification functions - GT-2444
  return (
    <View style={[styles.wrapper, styles.shadowStyle]}>
      <Pressable onPress={() => navigate(NavigationKeys.BUSINESS_PROFILE)}>
        <Avatar
          s3Key={business?.businessLogo || ''}
          imageFetchType={Constants.IMAGE_FETCH_TYPE.PROFILE}
          avatarProps={{
            altText: Util.getAcronym(business?.businessName || ''),
            size: ComponentOptions.AVATAR_SIZE.EXTRA_SMALL,
            hasBorder: true,
            borderColor: AppColors.common.primary,
            wrapperStyles: styles.iconWrapper,
            uniqueId: business?.businessId,
          }}
        />
      </Pressable>
      <View>
        <View style={{ position: 'relative' }}>
          <Icon
            name={'Home'}
            wrapperStyles={styles.iconWrapper}
            fill={getIconColor(NavigationKeys.HOME)}
            size={20}
            onPress={() => {
              navigate(NavigationKeys.HOME)
            }}
          />
          {renderUnreadNotificationCount(NavigationName.HOME)}
        </View>
        <View style={{ position: 'relative' }}>
          <Icon
            name={'Chat'}
            wrapperStyles={styles.iconWrapper}
            fill={getIconColor(NavigationKeys.CHAT)}
            size={20}
            onPress={() => navigate(NavigationKeys.CHAT)}
          />
          {renderUnreadNotificationCount(NavigationName.CHAT)}
        </View>
        <Icon
          name={'Search'}
          wrapperStyles={styles.iconWrapper}
          fill={getIconColor(NavigationKeys.FIND)}
          size={20}
          onPress={() => navigate(NavigationKeys.FIND)}
        />
      </View>
      <View>
        <Icon
          name={'Settings'}
          wrapperStyles={styles.iconWrapper}
          fill={getIconColor(NavigationKeys.SETTINGS)}
          size={20}
          onPress={() => {
            navigate(NavigationKeys.SETTINGS)
          }}
        />
        <Icon
          name={'Sms'}
          wrapperStyles={styles.iconWrapper}
          fill={getIconColor('')}
          size={20}
          onPress={() => toggleMenu()}
        />
      </View>
    </View>
  )
}

export default Menu
