import { useState, useEffect, useRef } from 'react'

function useAudioPlayer(audioUri: string | undefined) {
  const audioRef = useRef<any | undefined>()
  const [duration, setDuration] = useState<number>(0)
  const [curTime, setCurTime] = useState<number>(0)
  const [playing, setPlaying] = useState(false)
  const [isCompleted, setCompleted] = useState<boolean>(false)

  useEffect(() => {
    if (audioUri !== '') {
      const audio = new Audio(audioUri)
      audioRef.current = audio
    }
  }, [audioUri])

  useEffect(() => {
    if (audioRef.current) {
      const setAudioData = () => {
        setDuration(audioRef.current.duration)
      }
      audioRef.current.addEventListener('loadeddata', setAudioData)
      const setAudioTime = () => {
        setCurTime(audioRef.current.currentTime)
      }
      audioRef.current.addEventListener('timeupdate', setAudioTime)
      audioRef.current.addEventListener('ended', () => setPlaying(false))

      return () => {
        audioRef.current.removeEventListener('loadeddata', setAudioData)
        audioRef.current.removeEventListener('timeupdate', setAudioTime)
        audioRef.current.removeEventListener('ended', () => setPlaying(false))
      }
    }
  }, [audioRef, audioUri])

  useEffect(() => {
    if (duration > 0 && curTime == duration) {
      setCompleted(true)
    }
  }, [curTime])

  function onPlay() {
    if (audioRef.current) {
      audioRef.current.play()
      setPlaying(true)
      setCompleted(false)
    }
  }
  function onPause() {
    if (audioRef.current) {
      audioRef.current.pause()
      setPlaying(false)
    }
  }

  function onSeeking(time: number) {
    if (audioRef.current) {
      audioRef.current.currentTime = time
    }
  }

  function onUnmounted() {
    if (audioRef.current) {
      audioRef.current.load()
    }
  }

  return {
    curTime,
    duration,
    playing,
    onPlay,
    onPause,
    isCompleted,
    onSeeking,
    onUnmounted,
  }
}

export default useAudioPlayer
